import { Socket } from "socket.io-client";
import type { ServerToClientEvents, ClientToServerEvents } from "../../../../../node_ts/src/services/modules/socket.io/ClientsManager";
import { BuildContext } from "../../components/utils/build_context";
import { useClientSocket } from "./useClientSocket";

export type SocketType = Socket<ServerToClientEvents, ClientToServerEvents>;

// eslint-disable-next-line no-empty-pattern
const CTX = BuildContext(({ }) => {
  const socket = useClientSocket();

  return {
    socket,
  };
});

export const useSocket = CTX.useContext;

export const SocketProvider = CTX.Provider;

export const withSocket = CTX.withContext;
