import { useMemo } from "react";


export declare type ValuesOf<T extends readonly unknown[]> = T[number];

export function useCssHandles<T extends readonly string[]>(key: string, names: T, { blockClass }: { blockClass?: string } = {}) {
    return useMemo(() => {
        const obj: any = {};
        obj["ROOT"] = key + (blockClass ? ` ${key}--${blockClass}` : "");
        names.forEach(name => {

            const list: string[] = [];
            list.push(`${key}-${name}`)
            if (blockClass) list.push(`${key}-${name}--${blockClass}`)
            obj[name] = list.join(" ")
        });
        return obj as Record<ValuesOf<typeof names>, string> & { ROOT: typeof key };
    }, []);
}
