import React, { useEffect } from "react";
import { NavigationType, useLocation, useNavigationType } from "react-router-dom";
import TagManager from "react-gtm-module";
import { scrollTo, scrollToElement } from "./utils/utils";
import { refPeluchan } from "../../contexts/PeluchanContext";


const ScrollMemory = () => {
    const location = useLocation()
    const navType: NavigationType = useNavigationType();

    useEffect(() => {
        // ⚠️Este hash es el #commentId de la url, no tiene relacion el con el hash de state del historial.

        TagManager.dataLayer({
            dataLayer: {
                event: "pageview",
                pathname: location.pathname,
                search: location.search,
                hash: location.hash,
                username: refPeluchan()?.login?.user?.username
            }
        })
        if (navType == NavigationType.Push) {
            if (location.hash === "") {
                location.state?.elementID
                    ? scrollToElement(0, location.state?.elementID)
                    : scrollTo(0);
            }
        }
    }, [location.pathname, location.search, location.hash]);

    return null;
}


export default ScrollMemory;