import React, { useMemo, useState } from "react";
import { refPeluchan, usePeluchan } from "../../../../contexts/PeluchanContext";
import { useShout } from "../item-shout"
import Buttons from '../../Comment/buttos';
import SendShout from "../../../../components/modal/sendshout";
import { LazyState, TempState } from "../../../../components/utils/LazyState";
import cn from "classnames";
import TagManager from "react-gtm-module";

function Placeholder() {
    return <div>
        <div className="lazy-loading" style={{
            width: "164px",
            height: "25px",
            borderRadius: "3px"
        }} />
        <div className="lazy-loading" style={{
            width: "50px",
            height: "25px",
            float: "right",
            transform: "translate(0px, -25px)",
            borderRadius: "3px"
        }} />
        {/* <div className="lazy-loading" style={{
            width: "50px",
            height: "25px",
            transform: "translate(114px, -50px)"
        }}/> */}
    </div>
}

const event_like = async (shout, like, dom) => {
    const { author, _id, up, down } = shout;
    if (!author) return;
    if (refPeluchan()?.login.my(author._id)) return;

    if (up || down) {
        window.log(`Ya votaste a este shout`);
        return false;
    }

    var result = await window.POST(`/api/shout/like`, {
        user_id: author._id,
        shout_id: _id,
        like: like
    });

    dom.classList.add("active");
    if (result == null || result.error) {
        dom.classList.remove("active");
        window.log(`${result.error}`, "red");
        return false;
    }
    if (like === 1) shout.up = true;
    if (like === -1) shout.down = true;

    const num = parseInt(dom.querySelector("span").innerText);
    if (!isNaN(num)) {
        dom.querySelector("span").innerText = num + 1;
    }

    TagManager.dataLayer({
        dataLayer: {
            event: "like",
            type: "shout",
            shoutId: _id,
            authorId: author._id,
            username: refPeluchan().login.user.username,
            like
        }
    })

    return true
}
const event_shared = async (shout, dom) => {
    const { author, _id } = shout;
    if (!author) return;

    var preview = { content: shout, type: "shout" };
    if (shout.body.content.native && !shout.text) preview = shout.body.content.native;

    window.popup.load((
        <SendShout defauld={{
            text: "",
            etiquetas: [],
            data: [{
                id: "shared-shout",
                type: "link" as any,
                content: {
                    url: `${window.location.origin}/shout/${_id}`
                }
            }]
        }}
            memory={false}
            defauld_setting={{
                native: preview
            }} onSend={() => {
                dom.classList.add("active");
                const num = parseInt(dom.querySelector("span").innerText);
                if (!isNaN(num)) {
                    dom.querySelector("span").innerText = num + 1;
                }
            }} />
    ));
}


export const ShoutFooter = ({ className = "" }) => {
    const { login: { isLogin } } = usePeluchan();
    const {
        shout,
        components: { ShoutLink, ShoutLazyLoad },
    } = useShout();
    const [isLoading, setLoading] = useState(0)

    let {
        _id,
        up, down, shared,
        positivos = NaN, negativos = NaN, shareds = NaN,
        meta_comments = NaN,
    } = shout;

    const stateLikeKey = _id + "-state-like"
    const stateLike = useMemo(() => LazyState.get(stateLikeKey) || 0, [_id, stateLikeKey])

    if (stateLike == 1) up = true
    if (stateLike == -1) down = true

    const onLike = (e) => {
        if (isLoading) return
        setLoading(1)
        event_like(shout, 1, e).then(r => {
            if (!r) return
            LazyState.set(stateLikeKey, 1)
        }).finally(() => {
            setLoading(0)
        })
    }
    const onDislike = (e) => {
        if (isLoading) return
        setLoading(2)
        event_like(shout, -1, e).then(r => {
            if (!r) return
            LazyState.set(stateLikeKey, -1)
        }).finally(() => {
            setLoading(0)
        })
    }

    const CONTENT = <><div className={className + " relative h-[25px]"}>
        <Buttons
            title="Dar positivo"
            name="like"
            Click={onLike}
            className={cn("mr-2", { "active": isLogin && up, "opacity-50": isLoading == 1 })}
        >
            {positivos + stateLike}
        </Buttons>
        <Buttons
            title="Dar negativo"
            name="dislike"
            Click={onDislike}
            className={cn("mr-2", { "active": isLogin && down, "opacity-50": isLoading == 2 })}
        >
            {negativos + stateLike}
        </Buttons>
        <Buttons
            title="Compartir shout"
            name="shared"
            Click={(e) => event_shared(shout, e)}
            className={cn("", { "active": isLogin && shared })}
        >
            {shareds + stateLike}
        </Buttons>
        {shout.hidden_content && <div className="inline-block text-sm font-mono text-red-500/70 align-top mt-[.25rem] ml-2">#NSFW</div>}
        <div className="absolute right-0 top-0 ">
            <ShoutLink className="block h-[25px]">
                <Buttons name="comment" >{meta_comments}</Buttons>
            </ShoutLink>
        </div>
    </div>

    </>

    return CONTENT

    // return <ShoutLazyLoad placeholder={<Placeholder/>} height={"25px"} style={{height: "25px"}} offset={1000} classNamePrefix="footer mt-2" unmountIfInvisible={true}>
    //     {CONTENT}
    // </ShoutLazyLoad>
}