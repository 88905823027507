import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = ({ color, ...props }: SVGProps<SVGGElement> & {
    color: string
}) => (
    <>
        <g
            {...props}
            transform={`translate(1.1 5.5), scale(0.63), rotate(-15)`}
        >
            <path fill={color} d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z" />
        </g>
    </>
)

export default ({ borde1, backgroundShadow1 }: SVGProps<SVGGElement> & {
    borde1: string,
    backgroundShadow1: string
}) => (
    <>
        <g transform="translate(1.4 1)" >
            <Icon color={backgroundShadow1} />
        </g>
        <Icon color={borde1} />
    </>
)
