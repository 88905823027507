import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';


export const FormControlLabelBlue = styled(FormControlLabel)(({ theme, className }) => ({
    [`& .MuiFormControlLabel-label`]: {
        fontSize: "12px",
        fontWeight: "bold",
        color: "inherit",
    },
    '& .MuiSwitch-sizeMedium': {
        margin: "-2px 0px"
    },
    '& .MuiSwitch-switchBase': {
        padding: "7px !important"
    },
    '& .MuiSwitch-thumb': {
        width: "10px !important",
        height: "10px !important",
        color: className.includes("active") ? "#3b8bb2" : "#5d6783"
    }
}));

export const FormControlLabelPick = styled(FormControlLabel)(({ theme, className }) => ({
    [`& .MuiFormControlLabel-label`]: {
        fontSize: "12px",
        fontWeight: "bold",
        color: "inherit",
    },
    '& .MuiSwitch-sizeMedium': {
        margin: "-2px 0px"
    },
    '& .MuiSwitch-switchBase': {
        padding: "7px !important"
    },
    '& .MuiSwitch-thumb': {
        width: "10px !important",
        height: "10px !important",
        color: className.includes("active") ? "#b23b72" : "#5d6783"
    }
}));

export const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: pink[600],
        '&:hover': {
            backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: pink[600],
    },
    '& .MuiSwitch-sizeMedium': {
        margin: "-2px 0px"
    }
}));

export const FormControlLabelPickShout = styled(FormControlLabel)(({ theme, className }) => ({
    [`& .MuiFormControlLabel-label`]: {
        fontSize: "10px",
        fontWeight: 800,
        color: "inherit",
    },
    '& .MuiSwitch-sizeMedium': {
        margin: "-2px 0px"
    },
    '& .MuiSwitch-switchBase': {
        padding: "7px !important"
    },
    '& .MuiSwitch-thumb': {
        width: "10px !important",
        height: "10px !important",
        color: className.includes("active") ? "#b23b72" : "#5d6783"
    }
}));

export const PinkSwitchShout = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: pink[600],
        '&:hover': {
            backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: pink[600],
    },
    '& .MuiSwitch-sizeMedium': {
        margin: "-2px 0px"
    }
}));

export const SwitchNanoB: React.FC<React.ComponentProps<typeof PinkSwitchShout> & { label: string, labelClassName?: string }> = ({ checked, label, labelClassName, ...args }) => {
    return <FormControlLabelPick className={"m-0 bg-[#181e2e] pt-[2px] pr-[9px] pb-[2px] pl-[3px] rounded-xl font-sans font-bold m-0 p-0 rounded-xl font-sans font-bold  " + (checked ? "active text-[#89335a]" : "text-[#5d6783]") + " " + labelClassName}
        control={//0px 9px 0px 2px !important
            <PinkSwitch size="small" checked={checked} {...args} />
        }
        label={label}
    />
}

export const SwitchNano: React.FC<React.ComponentProps<typeof PinkSwitchShout> & { label: string, labelClassName?: string }> = ({ checked, label, labelClassName, ...args }) => {
    return <FormControlLabelPickShout className={"m-0 p-0 rounded-xl font-sans font-bold  " + (checked ? "active text-[#89335a]" : "text-[#5d6783]") + " " + labelClassName}
        control={//0px 9px 0px 2px !important
            <PinkSwitchShout size="small" checked={checked} {...args} />
        }
        label={label}
    />
}