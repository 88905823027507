import { useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import React from "react"
import { useMemoHeight } from "../../hooks/useMemoHeight"

export const LazyView: React.FC<React.PropsWithChildren & {
    id: number | string,
    observer?: Parameters<typeof useInView>[0],
    placeholder?: (height: number) => JSX.Element,
    className?: string,
    pinMemoHeight?: boolean,
    minSize?: string
}> = ({ id, children, observer = {}, placeholder, className, minSize = "400px", pinMemoHeight = false }) => {
    const [memoHeight, setMemoHeight] = useMemoHeight(`lazyview-${id}`);
    const refRoot = useRef<HTMLDivElement>()

    const { ref, inView, entry } = useInView({
        threshold: 0,
        initialInView: false,
        onChange: (inViewNew) => {
            if (inViewNew) return
            if (!inView) return
            if (!refRoot.current) return
            setMemoHeight(refRoot.current.clientHeight)
        },
        ...observer
    });


    return <div className={className} ref={(e) => {
        ref(e)
        refRoot.current = e
    }}
        style={(inView) ? {
            ...memoHeight && pinMemoHeight ? {
                minHeight: `${memoHeight}px`
            } : {}

        } : {
            height: memoHeight ? `${memoHeight}px` : minSize
        }}
    >
        {inView ? children : (placeholder ? placeholder(memoHeight) : null)}
    </div>
}