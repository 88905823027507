import React from "react"
import { Button } from "../components/styleguide/components/Button"
import { Panel } from "../components/styleguide/components/Panel"
import cn from "classnames"

export type PageErrorProps = {
    message: string,
    status: number
}

const code_text = [
    [100, "Continue", "The request has succeeded."],
    [101, "Switching Protocols", "The server is switching protocols."],
    [200, "OK", "The request has succeeded."],
    [201, "Created", "The request has been fulfilled and resulted in a new resource being created."],
    [202, "Accepted", "The request has been accepted for processing, but the processing has not been completed."],
    [203, "Non-Authoritative Information", "The server is a transforming proxy that received a 200 status code."],
    [204, "No Content", "The server has fulfilled the request but does not need to return an entity-body."],
    [205, "Reset Content", "The server has fulfilled the request and the user agent should reset the document view."],
    [206, "Partial Content", "The server has fulfilled the partial GET request for the resource."],
    [300, "Multiple Choices", "The requested resource corresponds to any one of a set of representations."],
    [301, "Moved Permanently", "The requested resource has been assigned a new permanent URI."],
    [302, "Found", "The requested resource resides temporarily under a different URI."],
    [303, "See Other", "The requested resource can be found under a different URI."],
    [304, "Not Modified", "The requested resource has not been modified since the last request."],
    [305, "Use Proxy", "The requested resource must be accessed through the proxy given by the Location field."],
    [306, "Switch Proxy", "No longer used."],
    [307, "Temporary Redirect", "The requested resource resides temporarily under a different URI."],
    [308, "Permanent Redirect", "The requested resource has been assigned a new permanent URI."],
    [400, "Bad Request", "The request could not be understood by the server due to malformed syntax."],
    [401, "Unauthorized", "The request requires user authentication."],
    [402, "Payment Required", "Reserved for future use."],
    [403, "Forbidden", "The server understood the request, but is refusing to authorize it."],
    [404, "Not Found", "The requested resource could not be found but may be available again in the future."],
    [405, "Method Not Allowed", "The method specified in the Request-Line is not allowed for the requested resource."],
    [406, "Not Acceptable", "The requested resource is only capable of generating content not acceptable according to the Accept headers sent in the request."],
    [407, "Proxy Authentication Required", "The client must first authenticate itself with the proxy."],
    [408, "Request Timeout", "The client did not produce a request within the time that the server was prepared to wait."],
    [409, "Conflict", "The request could not be completed due to a conflict with the current state of the resource."],
    [410, "Gone", "The requested resource is no longer available at the server and no forwarding address is known."],
    [411, "Length Required", "The server refuses to accept the request without a defined Content-Length."],
    [412, "Precondition Failed", "The server does not meet one of the preconditions that the requester put on the request."],
    [413, "Request Entity Too Large", "The server is refusing to process a request because the request entity is larger than the server is willing or able to process."],
    [414, "Request-URI Too Long", "The server is refusing to service the request because the Request-URI is longer than the server is willing to interpret."],
    [415, "Unsupported Media Type", "The server is refusing to service the request because the entity of the request is in a format not supported by the requested resource for the requested method."],
    [416, "Requested Range Not Satisfiable", "The request included a Range request-header field, and not all ranges-specifier values were satisfiable."],
    [417, "Expectation Failed", "The expectation given in an Expect request-header field could not be met by the server."],
    [418, "I'm a teapot", "The server refuses to brew coffee because it is a teapot."],
    [500, "Internal Server Error", "The server encountered an unexpected condition that prevented it from fulfilling the request."],
    [501, "Not Implemented", "The server does not support the functionality required to fulfill the request."],
    [502, "Bad Gateway", "The server, while acting as a gateway or proxy, received an invalid response from the upstream server it accessed in attempting to fulfill the request."],
    [503, "Service Unavailable", "The server is currently unable to handle the request due to a temporary overloading or maintenance of the server."],
    [504, "Gateway Timeout", "The server, while acting as a gateway or proxy, did not receive a timely response from the upstream server specified by the URI."],
    [505, "HTTP Version Not Supported", "The server does not support, or refuses to support, the HTTP protocol version that was used in the request message."],
    [506, "Variant Also Negotiates", "Transparent content negotiation for the request results in a circular reference."],
    [507, "Insufficient Storage", "The server is unable to store the representation needed to complete the request."],
    [508, "Loop Detected", "The server detected an infinite loop while processing the request."],
    [509, "Bandwidth Limit Exceeded", "The bandwidth limit has been exceeded."],
    [510, "Not Extended", "Further extensions to the request are required for the server to fulfill it."],
    [511, "Network Authentication Required", "The client needs to authenticate to gain network access."]
];
const code_text_spanish = [
    [100, "Continuar", "La solicitud ha tenido éxito."],
    [101, "Cambio de Protocolos", "El servidor está cambiando de protocolos."],
    [200, "OK", "La solicitud ha tenido éxito."],
    [201, "Creado", "La solicitud ha sido cumplida y ha resultado en la creación de un nuevo recurso."],
    [202, "Aceptado", "La solicitud ha sido aceptada para procesamiento, pero el procesamiento no ha sido completado."],
    [203, "Información No Autorizada", "El servidor es un proxy transformador que recibió un código de estado 200."],
    [204, "No hay Contenido", "El servidor ha cumplido la solicitud pero no necesita devolver un cuerpo de entidad."],
    [205, "Restablecer Contenido", "El servidor ha cumplido la solicitud y el agente usuario debe restablecer la vista del documento."],
    [206, "Contenido Parcial", "El servidor ha cumplido la solicitud parcial GET para el recurso."],
    [300, "Elecciones Múltiples", "El recurso solicitado se corresponde con cualquier una de una serie de representaciones."],
    [301, "Movido Permanentemente", "El recurso solicitado ha sido asignado una nueva URI permanente."],
    [302, "Encontrado", "El recurso solicitado reside temporalmente bajo una URI diferente."],
    [303, "Ver Otro", "El recurso solicitado se puede encontrar bajo una URI diferente."],
    [304, "No Modificado", "El recurso solicitado no ha sido modificado desde la última solicitud."],
    [305, "Usar Proxy", "El recurso solicitado debe ser accedido a través del proxy dado por el campo de ubicación."],
    [306, "Cambiar Proxy", "No se utiliza más."],
    [307, "Redirección Temporal", "El recurso solicitado reside temporalmente bajo una URI diferente."],
    [308, "Redirección Permanente", "El recurso solicitado ha sido asignado una nueva URI permanente."],
    [400, "Solicitud Incorrecta", "La solicitud no pudo ser entendida por el servidor debido a una sintaxis malformada."],
    [401, "No Autorizado", "La solicitud requiere autenticación de usuario."],
    [402, "Pago Requerido", "Reservado para uso futuro."],
    [403, "Prohibido", "El servidor entendió la solicitud, pero se niega a autorizarla."],
    [404, "No Encontrado", "El recurso solicitado no pudo ser encontrado, pero puede estar disponible de nuevo en el futuro."],
    [405, "Método No Permitido", "El método especificado en la línea de solicitud no está permitido para el recurso solicitado."],
    [406, "No Aceptable", "El recurso solicitado solo es capaz de generar contenido no aceptable según los encabezados Accept enviados en la solicitud."],
    [407, "Autenticación de Proxy Requerida", "El cliente debe autenticarse primero con el proxy."],
    [408, "Tiempo de Espera de Solicitud", "El cliente no produjo una solicitud dentro del tiempo que el servidor estaba dispuesto a esperar."],
    [409, "Conflicto", "La solicitud no pudo ser completada debido a un conflicto con el estado actual del recurso."],
    [410, "Desaparecido", "El recurso solicitado ya no está disponible en el servidor y no se conoce una dirección de reenvío."],
    [411, "Longitud Requerida", "El servidor se niega a aceptar la solicitud sin una longitud de contenido definida."],
    [412, "Condición Previa Fallida", "El servidor no cumple con una de las condiciones previas que el solicitante puso en la solicitud."],
    [413, "Entidad de Solicitud Demasiado Grande", "El servidor se niega a procesar la solicitud porque la entidad de la solicitud es demasiado grande para que el servidor esté dispuesto o pueda procesarla."],
    [414, "URI de Solicitud Demasiado Larga", "El servidor se niega a servir la solicitud porque la URI de la solicitud es más larga de lo que el servidor está dispuesto a interpretar."],
    [415, "Tipo de Medio No Soportado", "El servidor se niega a servir la solicitud porque la entidad de la solicitud está en un formato no soportado por el recurso solicitado para el método solicitado."],
    [416, "Rango de Solicitud No Satisfactorio", "La solicitud incluyó un campo de encabezado de rango, y no todos los valores del especificador de rango fueron satisfactorios."],
    [417, "Expectativa Fallida", "La expectativa dada en un campo de encabezado de Expect no pudo ser cumplida por el servidor."],
    [418, "Soy una Tetera", "El servidor se niega a preparar café porque es una tetera."],
    [500, "Error Interno del Servidor", "El servidor encontró una condición inesperada que impidió que cumpliera la solicitud."],
    [501, "No Implementado", "El servidor no admite la funcionalidad necesaria para cumplir la solicitud."],
    [502, "Puerta de Enlace Incorrecta", "El servidor, mientras actuaba como puerta de enlace o proxy, recibió una respuesta no válida del servidor upstream al que se accedió para cumplir la solicitud."],
    [503, "Servicio No Disponible", "El servidor está actualmente sobrecargado o en mantenimiento, por lo que no puede manejar la solicitud."],
    [504, "Tiempo de Espera de Puerta de Enlace", "El servidor, mientras actuaba como puerta de enlace o proxy, no recibió una respuesta oportuna del servidor upstream especificado por la URI."],
    [505, "Versión de HTTP No Soportada", "El servidor no admite o se niega a admitir la versión del protocolo HTTP utilizada en el mensaje de solicitud."],
    [506, "Variante También Negocia", "La negociación de contenido transparente para la solicitud resulta en una referencia circular."],
    [507, "Almacenamiento Insuficiente", "El servidor es incapaz de almacenar la representación necesaria para completar la solicitud."],
    [508, "Bucle Detectado", "El servidor detectó un bucle infinito mientras procesaba la solicitud."],
    [509, "Límite de Ancho de Banda Excedido", "El límite de ancho de banda ha sido excedido."],
    [510, "No Extendido", "Se requieren extensiones adicionales para que el servidor cumpla la solicitud."],
    [511, "Autenticación de Red Requerida", "El cliente necesita autenticarse para obtener acceso a la red."]
];

const get = (status: number): [number, string, string] => {
    const value = code_text_spanish.find(i => i[0] == status)
    if (value) return value as any
    return code_text_spanish.find(i => i[0] == 418) as any
}

export const PageError: React.FC<PageErrorProps> = ({ message, status }) => {
    const value = get(status)

    const colorStyles = {
        P200: "bg-green-500/10 text-green-500",
        P300: "bg-cyan-500/10  text-cyan-500",
        P400: "bg-yellow-500/10  text-yellow-500",
        P500: "bg-red-500/10  text-red-500"
    }

    let color = colorStyles.P200
    if (status >= 300) color = colorStyles.P300
    if (status >= 400) color = colorStyles.P400
    if (status >= 500) color = colorStyles.P500

    return (<div className="flex  text-center flex-col h-[calc(100vh-100px)] md:h-[calc(100vh-200px)] items-center justify-center">
        <Panel padding="xl" className="text-center flex flex-col font-mono justify-center max-w-[460px]">
            <h1 className="text-[6rem] md:text-[10rem] font-bold m-0 p-0 text-blue-400 md:-mt-4">{value[0]}</h1>
            <h3 className="text-[1.5rem] font-[sans-serif] md:text-[2rem] font-bold m-0 p-0 -mt-4 md:-mt-6 text-blue-400">{value[1]}</h3>
            <span className="font-bold m-0 p-0 mt-4 font-[sans-serif] text-gray-400 max-w-[66vw]">{value[2]}</span>
            {message && <div className="mt-6">
                <span className={cn("rounded-md w-max p-2 px-3 text-center max-w-[66vw] shadow-md", color)}>
                    <span className="material-icons-outlined align-top translate-y-[1px] text-sm mr-2">report_problem</span>
                    {typeof message == "string" ? message : JSON.stringify(message)}
                </span>
            </div>}
            <Button.withLink to={"/"} className="mt-6" size="size5" type="dark">
                <span className="material-icons-outlined align-top translate-y-[-1px] text-sm mr-2">arrow_back</span>
                Volver al Inicio
            </Button.withLink>
        </Panel>
    </div>
    )
}