import { NavigationType } from "react-router-dom";
import { refHistory } from "../../hooks/useHistory";
import { randomstring } from "./randomstring";
import { deleteFirstMatch } from "../../utils/deleteFirstMatch";

declare global {
    interface Window { lazyState: Record<string, any>; tempState: Record<string, any>; globalState: Record<string, any>; }
}

/**
 * Los estados se guardan sobre la pagina actual, lo que sigmifica que no se mantienen entre sitios.
 */
export class LazyState {

    static updateState(values: Record<string, any>) {
        refHistory().replace(values);
    }

    static getPageKey() {
        let key = history.state?.idx
        if (key == -1) console.error(new Error("history.state.idx es nulo!"))
        return { pageKey: key, newPage: refHistory().navigateType == NavigationType.Push };
    }
    static getInfoPage() {
        const key = this.getPageKey()
        return { key };
    }

    static newState() {
        return {
            stateKey: randomstring(5)
        }
    }

    static lastPage = undefined
    static getState() {
        if (!window.lazyState) window.lazyState = {}

        let { pageKey, newPage } = this.getPageKey()

        if (newPage && this.lastPage != pageKey) {
            window.lazyState[pageKey] = this.newState()
            this.trySaveId++;
        }
        this.lastPage = pageKey

        if (pageKey in window.lazyState) return window.lazyState[pageKey]
        const stateValues = refHistory().location?.state || {}
        const state = stateValues[`lazy-${pageKey}`] || this.newState()
        window.lazyState[pageKey] = state;
        return state;
    }

    static getStateKey() {
        return this.getState().stateKey
    }

    static set<T = any>(key, value: T, { noLazy = false, ttlLazy }: { noLazy?: boolean, ttlLazy?: number } = {}) {
        const state = this.getState();
        state[key] = value
        if (noLazy) this.SaveState()
        else this.trySaveState(ttlLazy)
    }

    static trySaveId = 0;
    private static trySaveState(ttl = 100) {
        const { pageKey } = this.getPageKey()
        this.trySaveId++;
        const trySaveId = this.trySaveId;
        setTimeout(() => {
            if (trySaveId !== this.trySaveId) return
            if (this.getPageKey().pageKey !== pageKey) return
            this.SaveState()
        }, ttl)
    }

    private static SaveState() {
        this.updateState({ [`lazy-${this.getPageKey().pageKey}`]: this.getState() })
    }

    static has(key) {
        const state = this.getState();
        return key && key in state;
    }

    static get<T = any>(key) {
        const state = this.getState()
        return key in state && state[key] as T
    }
}


/**
 * Los estados se guardan en memoria simulando el comportamiento de LazyState, lo que sigmifica que no se mantienen entre sitios y tampoco al refrescar.
 */
export class TempState {

    static getState() {
        if (!window.tempState) window.tempState = {}

        let { pageKey } = LazyState.getPageKey()
        if (pageKey in window.tempState) return window.tempState[pageKey]
        const state = {}
        window.tempState[pageKey] = state;
        return state;
    }

    static set<T = any>(key, value: T) {
        const state = this.getState();
        state[key] = value
    }

    static get<T = any>(key) {
        const state = this.getState()
        return key in state && state[key] as T
    }
    static del(key) {
        const state = this.getState();
        delete state[key]
    }
}

/**
 * Los estados se guardan en memoria simulando el comportamiento de LazyState pero de forma global, lo que sigmifica que si se mantienen entre sitios pero no al refrescar.
 */
export class GlobalState {

    static getState() {
        if (!window.globalState) window.globalState = {}
        return window.globalState;
    }

    static set<T = any>(key, value: T) {
        const state = this.getState();
        state[key] = value
    }

    static get<T = any>(key) {
        const state = this.getState()
        return key in state && state[key] as T
    }

    static del(key) {
        const state = this.getState();
        delete state[key]
    }
}

type LocalStateTTL = {
    /** Key del elemento */
    i: string,
    /** Momento en el que se eliminara del elemento */
    d: number
}

/**
 * Los estados se guardan en localStorage simulando el comportamiento de LazyState pero de forma global, 
 * lo que significa que se mantienen entre sitios y al refrescar la página.
 */
export class LocalState {
    /**
     * Obtiene el estado actual del localStorage.
     * @returns {Storage} El localStorage.
     */
    static getState(): Storage {
        return window.localStorage;
    }

    /**
     * Construye una clave completa para almacenar en localStorage.
     * @param {string} key - La clave base.
     * @returns {string} La clave completa con prefijo.
     */
    static buildKey(key: string): string {
        return `localState-${key}`;
    }

    /**
     * Obtiene la lista de información TTL.
     * @returns {LocalStateTTL[]} La lista de objetos TTL.
     */
    static getInfos(): LocalStateTTL[] {
        const raw = localStorage.getItem(this.buildKey("$ttl"));
        if (raw) {
            try {
                return JSON.parse(raw) as LocalStateTTL[];
            } catch (error) {
                console.error('Error parsing TTL info:', error);
                return [];
            }
        }
        return [];
    }

    /**
     * Guarda la lista de información TTL en localStorage.
     * @param {LocalStateTTL[]} raw - La lista de objetos TTL a guardar.
     */
    static setTTL(raw: LocalStateTTL[]): void {
        try {
            localStorage.setItem(this.buildKey("$ttl"), JSON.stringify(raw));
        } catch (error) {
            console.error('Error saving TTL info:', error);
        }
    }

    /**
     * Guarda un valor en localStorage con un TTL especificado.
     * @param {string} key - La clave del estado a guardar.
     * @param {T} value - El valor del estado a guardar.
     * @param {number} ttl - El tiempo de vida en segundos.
     */
    static set<T = any>(key: string, value: T, ttl: number): void {
        const list = this.cleanExpiredTTL();

        key = this.buildKey(key);
        const state = this.getState();
        try {
            state.setItem(key, JSON.stringify({ value }));
        } catch (error) {
            console.error('Error saving state:', error);
        }

        this.setTTL([...deleteFirstMatch(list, (l) => l.i === key), {
            i: key,
            d: Date.now() + (ttl * 1000)
        }]);
    }

    /**
     * Obtiene un valor del localStorage, verificando si su TTL ha expirado.
     * @param {string} key - La clave del estado a obtener.
     * @returns {T | undefined} El valor almacenado o undefined si no existe o ha expirado.
     */
    static get<T = any>(key: string): T | undefined {
        this.cleanExpiredTTL();

        key = this.buildKey(key);
        const state = this.getState();
        if (state[key]) {
            try {
                return JSON.parse(state[key]).value as T;
            } catch (error) {
                console.error('Error parsing state:', error);
            }
        }
        return undefined;
    }

    /**
     * Limpia los elementos con TTL expirado.
     * @returns {LocalStateTTL[]} La lista de TTLs actualizada.
     */
    static cleanExpiredTTL(): LocalStateTTL[] {
        const list = this.getInfos();
        const now = Date.now();
        const validList = list.filter(item => {
            if (item.d < now) {
                localStorage.removeItem(item.i);
                return false;
            }
            return true;
        });
        if (validList.length !== list.length) {
            this.setTTL(validList);
        }
        return validList;
    }
}
