import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'

import SendMessage from "../../../components/modal/sendmessage";

import Tooltip from "../../../components/utils/tooltip";
import SR from "../../../components/utils/resources";

import MScreen from "../../../utils/MScreen";
import { refPeluchan, usePeluchan } from '../../../contexts/PeluchanContext';
import SendDelete from '../../../components/modal/senddelete';
import { Imagen, ImgDefault404 } from '../../../components/Imagen/Imagen';
import { PeluchanUpload } from '../../../utils/services/PeluchanUpload';
import { Timer } from '../../../components/styleguide/components/Timer';
import { Button } from '../../../components/styleguide/components/Button';
import { PeluchanCDN } from '../../../utils/services/PeluchanCDN';
import { refEnv } from '../../../contexts/EnvironmentContext';

var rela = 16 / 16;
var portada_width = 120;
var portada_height = rela * portada_width;

async function Seguir(user) {
    const { data, error } = await window.POST(`/api/user/fallow`, {
        _id: user._id,
        type: "user"
    });
    if (error || (!data && !error)) {
        if (!data && !error) {
            window.log("Servidor no responde", "red");
            return false;
        }
        window.log(error, "red");
        return false;
    }
    //window.log("ok","green");
    return true;
}

async function DejarDeSeguir(user) {
    const { data, error } = await window.POST(`/api/user/unfallow`, {
        _id: user._id,
        type: "user"
    });
    if (error || (!data && !error)) {
        if (!data && !error) {
            window.log("Servidor no responde", "red");
            return false;
        }
        window.log(error, "red");
        return false;
    }
    //window.log("ok","green");
    return true;
}

// async function LimitarUsuario(user, s) {
//     const { data, error } = await window.POST(`/api/user/update`, {
//         user_id: user._id,
//         user: {
//             priv: s ? 190 : 200
//         }
//     })
//     if (error || (!data && !error)) {
//         if (!data && !error) {
//             window.log("Servidor no responde", "red");
//             return;
//         }
//         window.log(error, "red");
//         return;
//     }

//     window.log(s ? "Se bajaron los privilegios a 190" : "Se le subieron sus privilegios a 200", "green");
// }


// async function Ban(user, s) {
//     const { data, error } = await window.POST(`/api/user/update`, {
//         user_id: user._id,
//         user: {
//             priv: s ? 4 : 200
//         }
//     })
//     if (error || (!data && !error)) {
//         if (!data && !error) {
//             window.log("Servidor no responde", "red");
//             return;
//         }
//         window.log(error, "red");
//         return;
//     }

//     window.log(s ? "Se bajaron los privilegios a 4" : "Se le subieron sus privilegios a 200", "green");

// }

// async function DesBanIP(user) {
//     window.popup.load(
//         <SendDelete
//             title={`Desbanear todas las IPs de @${user.username}.`}
//             requered = {false}
//             preview={(text)=>
//                 <>
//                     Se desbanearon 0 IPs de <a href="#">@</a>{user.username} por @${refPeluchan().login.user.username} {text}
//                 </>
//             }
//             _delete={async (porque)=>{
//                 const { data, error } = await window.POST(`/api/user/desbanip`, {
//                     user_id: user._id,
//                     ...porque?{porque}:{}
//                 });
//                 if (error || (!data && !error)) {
//                     if (!data && !error) {
//                         window.log("Servidor no responde", "red");
//                         return;
//                     }
//                     window.log(error, "red");
//                     return;
//                 }

//                 window.log(data.report, "green");
//             }}
//         />
//     );
// }

// export function UserOptions({ user, className, position = "left" }) {
//     const { login: { isLogin, rol: { mod }, my } } = usePeluchan();
//     const { _id, username } = user;
//     const IsMy = my(_id);
//     return (
//         <Tooltip className={className} offset={[0, 0]} placement={position} title={"Opciones de usuario"}>
//             {
//                 [{
//                     text: "Opciones",
//                     show: (!IsMy && mod) && isLogin,
//                     to: `/user/${_id}/${username}/setting`,
//                     icon: SR._icons._usersetting
//                 },{
//                     text: "Moderacion",
//                     show: (!IsMy && mod) && isLogin,
//                     to: `/admin/dashboard/usuarios/${_id}/moderacion`,
//                     icon: SR._icons._usersetting
//                 }]
//             }
//         </Tooltip>
//     );
// }


function Sex({ sex }) {
    if (sex === "H") return <img src="https://img.icons8.com/office/16/000000/male.png" title={"Hombre"} />;
    if (sex === "M") return <img src="https://img.icons8.com/office/16/000000/female.png" title={"Mujer"} />;
    if (sex === "AH-64") return <img src="https://img.icons8.com/color/24/000000/military-helicopter.png" title={"Boeing AH-64 Apache"} />;
    return null;
}


const Meta = ({ tittle_tooltip, tittle, total = 0, material_icon }) => {
    // if(total==0) return null
    return <div className="flex-grow-1  mx-0 px-0" title={tittle_tooltip}>
        <div className={"value flex items-center justify-center mt-1 " + (total == 0 ? "text-gray-400" : "text-[#b43857]")}>{`${total.toLocaleString()}`}<span className="material-icons text-base align-top ml-1">{material_icon}</span></div>
        <div className="meta font-sans text-gray-400 whitespace-nowrap text-[10px]">{tittle}</div>
    </div>
}


function RMetas({ user } = {}) {
    if (!user) user = window.user404;
    const { meta_comments = 0, meta_shouts = 0, meta_likes = 0, meta_posts = 0 } = user;

    return (
        <div className="flex metas flex-wrap" style={{ textAlign: "center" }}>
            <Meta tittle="Comentarios" tittle_tooltip="Cantidad de comentarios totales" total={meta_comments} material_icon="comment" />
            <Meta tittle="Me gustas" tittle_tooltip="Cantidad de me gustas totales" total={meta_likes} material_icon="thumb_up" />
            <div className="hernibar my-1"></div>
            <Meta tittle="Posteos" tittle_tooltip="Cantidad de post publicados" total={meta_posts} material_icon="feed" />
            <Meta tittle="Shouts" tittle_tooltip="Cantidad de shouts totales" total={meta_shouts} material_icon="sticky_note_2" />
        </div>
    );
}

function UserPanelLayout({ CPhoto, CName, CUsername, CPuntos, CButtons, CCreate, CMetas, CBackgroud, isMe }) {
    const ms = new MScreen(false);
    const za = ms.media({ min: 768, max: 980 });
    const zb = ms.media({ min: 300, max: 370 });
    const zc = ms.media({ max: 299 });

    if (zc) {
        return (
            <div className='userpanel relative'>
                {CBackgroud}
                <div className="perfil  _nano _medio">
                    <div >
                        {CPhoto}
                        <div className="media-body space-y-2">
                            {CName}
                            {CUsername}
                            {CPuntos}
                            {CCreate}
                        </div>
                    </div>
                    <div className="perfil-linea line" />
                    {CButtons}
                    {CMetas}
                </div>
            </div>
        );
    }

    if (za || zb) {
        return (
            <div className='userpanel relative'>
                {CBackgroud}
                <div className="perfil _medio">
                    <div className="min-h-[120px]">
                        <div className='float-left'>
                            {CPhoto}
                        </div>
                        <div className="media-body ml-[calc(120px+.75rem)] space-y-2">
                            {CName}
                            {CUsername}
                            {CPuntos}
                            {CCreate}
                        </div>
                    </div>
                    {!isMe && <div className="perfil-linea line" />}
                    {CButtons}
                    {CMetas}
                </div>
            </div>
        );
    }
    return (
        <div className='userpanel relative'>
            {CBackgroud}
            <div className="perfil">
                <div className="min-h-[120px]">
                    <div className='float-left'>
                        {CPhoto}
                    </div>
                    <div className="media-body ml-[calc(120px+.75rem)] space-y-2">
                        {CName}
                        {CUsername}
                        {CPuntos}
                        {CCreate}
                        {CButtons}
                    </div>
                </div>
                {CMetas}
            </div>
        </div>
    );
}

const SeguirButton = ({ user }) => {
    const [isFollow, setFallow] = useState(user?.loSigo)
    const [isLoading, setLoading] = useState(false)
    if (!user) return null

    const onClick = () => {
        setLoading(true)
        if (isFollow) DejarDeSeguir(user).then(() => {
            setFallow(false)
        }).finally(() => setLoading(false))
        else Seguir(user).then(() => {
            setFallow(true)
        }).finally(() => setLoading(false))
    }

    return (
        <Button.IconAndText
            size="size3"
            icon={isFollow ? "person_off" : "person_add"}
            className={isFollow ? "siguiendo" : ""}
            type={'flatBlue'}
            children={isLoading ? <span className='-translate-y-[2px] inline-block'>{SR._icons._loading_dual_ring}</span> : (isFollow ? "Siguiendo" : "Seguir")}
            onClick={onClick}
        />
    );
}

export function UserPanel({ links = false, show_seguir = true, show_moderation = false, show_upload = true, user }) {
    const { login: { isLogin, my, rol } } = usePeluchan();
    if (!user) user = window.user404;
    var { name, username, meta_puntos, photo, sex, _id, create, loSigo = false, online = false } = user;

    const [newFoto, setNewFoto] = React.useState()
    const [isUploading, setUploading] = React.useState()

    const ShowOptions = my(_id) && isLogin;

    // let online_on = online && <span className="onlineball" />;

    const handlerMensaje = () => window.popup.load(<SendMessage user={{ username: username, _id: _id }} />);

    const isShowButtons = !my(_id) && isLogin

    const UserButtons = <>
        <div className='!mt-3 flex'>
            <div className='flex-grow-1 flex space-x-2'>
                {isShowButtons && <>
                    {!show_seguir ? null : <SeguirButton key={_id} user={user} />}
                    <Button.IconAndText size="size3" icon='email' type='flatBlue' onClick={handlerMensaje} children="Mensaje" />
                </>}
            </div>
        </div>
        {isShowButtons && rol.mod && show_moderation &&
            <div>
                <Link to={`/admin/dashboard/usuarios/${_id}/moderacion`}>
                    <Button.IconAndText size="size3" icon='manage_accounts' type='primaryRed' children="Moderacion" />
                </Link>
            </div>
        }

    </>;


    const _photo = newFoto || photo;
    let PhotoPanel = <Imagen key={_id} pId='8j4PDaK2iS' id="photo_de_perfil" pDefault={ImgDefault404.nano} className="photo" alt='avatar' src={_photo} />;
    if (links) PhotoPanel = <Link to={`/user/${_id}/${username}`}>{PhotoPanel}</Link>;

    return (
        <>
            <UserPanelLayout
                isMe={my(_id)}
                CPhoto={
                    <div className="photo-container">
                        {PhotoPanel}
                        {ShowOptions && show_upload &&
                            <Fragment>
                                <div className="perfil-editar" onClick={() => {

                                    if (refEnv().tag.is("cdn_user")) {
                                        PeluchanCDN("profile", { loading: () => setUploading(true) }).then(async (result) => {
                                            const url = result.baseUrl
                                            return window.POST(`/api/user/update`, {
                                                user_id: _id,
                                                user: {
                                                    photo: url
                                                }
                                            }).then(({ data, error }) => {
                                                if (error) return window.log(error);
                                                setNewFoto(url);
                                                window.log("Completado!", "green");
                                            })
                                        }).catch((...args) => {
                                            console.log(args)
                                        }).finally(() => {
                                            setUploading(false);
                                        });
                                    } else {
                                        PeluchanUpload({ loading: () => setUploading(true) }).then(async (result) => {
                                            const url = result.data.url
                                            return window.POST(`/api/user/update`, {
                                                user_id: _id,
                                                user: {
                                                    photo: url
                                                }
                                            }).then(({ data, error }) => {
                                                if (error) return window.log(error);
                                                setNewFoto(url);
                                                window.log("Completado!", "green");
                                            })
                                        }).catch((...args) => {
                                            console.log(args)
                                        }).finally(() => {
                                            setUploading(false);
                                        });
                                    }

                                }}>
                                    <span className="material-icons">
                                        {
                                            isUploading ? (
                                                <div className="lds-circle"><div></div></div>
                                            ) : "edit"
                                        }
                                    </span>
                                    <span className="text">Editar</span>
                                </div>
                            </Fragment>
                        }
                        {/* {online_on} */}
                    </div>
                }

                CName={links ?
                    <Link to={`/user/${_id}/${username}`}><div className="name line-clamp-2">{name}</div></Link> :
                    <div className="name line-clamp-2">{name}</div>
                }

                CUsername={
                    <div className="username line-clamp-1"> {`@${username}`}</div>
                }
                CPuntos={
                    <div className="puntos-YzbTb leading-none" title={`Recibio ${meta_puntos} puntos en total.`}>{`${meta_puntos.toLocaleString()} Puntos`}</div>
                }

                CCreate={create ? <div className="createAt mb3 flex items-center inline-block">
                    <span className="material-icons text-sm align-top mr-1">date_range</span>
                    <Timer.withHover conteiner={(c) => <>Se registro {c}</>} time={create} />
                </div> : null}

                CButtons={UserButtons}

                CMetas={<RMetas user={user} />}

            // CBackgroud={<div className="absolute top-0 bottom-0 right-0 left-0 opacity-10 bg-cover" style={{background: "url('"+_photo+"')", filter: "blur(6px)"}}/>}
            />
        </>
    );
}
