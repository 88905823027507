import cn from "classnames"
import React from "react"


const MaterialIcon: React.FC<JSX.IntrinsicElements["span"]> = ({ className, ...args }) => {
    return <span {...args} className={cn("material-icons", className)} />
}

const MaterialIconOutlined: React.FC<JSX.IntrinsicElements["span"]> = ({ className, ...args }) => {
    return <span {...args} className={cn("material-icons-outlined", className)} />
}


export const CategoryIconByName: React.FC<{ slug: string, className?: string }> = ({ slug, className }) => {
    if (slug == "musica") return <MaterialIcon className={className}>music_note</MaterialIcon>
    if (slug == "arte") return <MaterialIcon className={className}>auto_awesome</MaterialIcon>
    if (slug == "offtopic") return <MaterialIcon className={className}>forum</MaterialIcon>
    if (slug == "humor") return <MaterialIcon className={className}>celebration</MaterialIcon>
    if (slug == "info") return <MaterialIconOutlined className={className}>info</MaterialIconOutlined>
    if (slug == "imagenes") return <MaterialIcon className={className}>image</MaterialIcon>
    if (slug == "noticias") return <MaterialIcon className={className}>newspaper</MaterialIcon>
    if (slug == "solidaridad") return <MaterialIcon className={className}>support</MaterialIcon>
    if (slug == "videos") return <MaterialIcon className={className}>video_library</MaterialIcon>
    if (slug == "download") return <MaterialIcon className={className}>file_download</MaterialIcon>
    if (slug == "tv-peliculas-series") return <MaterialIconOutlined className={className}>live_tv</MaterialIconOutlined>
    if (slug == "haslo-tu-mismo") return <MaterialIconOutlined className={className}>handyman</MaterialIconOutlined>
    if (slug == "peluchan") return <MaterialIconOutlined className={className}>local_parking</MaterialIconOutlined>
    if (slug == "recetas-y-cocina") return <MaterialIcon className={className}>restaurant_menu</MaterialIcon>
    if (slug == "pa11111ranormal") return <MaterialIconOutlined className={className}>token</MaterialIconOutlined>
    if (slug == "juegos") return <MaterialIcon className={className}>sports_esports</MaterialIcon>
    if (slug == "politica") return <MaterialIcon className={className}>account_balance</MaterialIcon>

    if (slug == "celulares") return <MaterialIconOutlined className={className}>phone_iphone</MaterialIconOutlined>
    if (slug == "manga-anime") return <MaterialIconOutlined className={className}>book</MaterialIconOutlined>
    if (slug == "comics") return <MaterialIconOutlined className={className}>book</MaterialIconOutlined>
    if (slug == "links") return <MaterialIconOutlined className={className}>link</MaterialIconOutlined>
    if (slug == "animaciones") return <MaterialIconOutlined className={className}>accessibility_new</MaterialIconOutlined>
    if (slug == "apuntes-y-monografias") return <MaterialIconOutlined className={className}>article</MaterialIconOutlined>
    if (slug == "deportes") return <MaterialIcon className={className}>sports_volleyball</MaterialIcon>
    if (slug == "ecologia") return <MaterialIconOutlined className={className}>forest</MaterialIconOutlined>
    if (slug == "Economia-negocios") return <MaterialIconOutlined className={className}>assured_workload</MaterialIconOutlined>
    if (slug == "reviews") return <MaterialIconOutlined className={className}>rate_review</MaterialIconOutlined>
    if (slug == "juegos-online") return <MaterialIcon className={className}>sports_esports</MaterialIcon>
    if (slug == "linux") return <MaterialIconOutlined className={className}>laptop</MaterialIconOutlined>
    if (slug == "mac") return <MaterialIconOutlined className={className}>laptop</MaterialIconOutlined>
    if (slug == "mascotas") return <MaterialIconOutlined className={className}>pets</MaterialIconOutlined>
    if (slug == "salud-y-bienestar") return <MaterialIconOutlined className={className}>self_improvement</MaterialIconOutlined>
    if (slug == "turismo") return <MaterialIconOutlined className={className}>directions_boat</MaterialIconOutlined>
    if (slug == "autos-y-motos") return <MaterialIconOutlined className={className}>directions_bus</MaterialIconOutlined>
    if (slug == "uff") return <MaterialIconOutlined className={className}>accessible_forward</MaterialIconOutlined>
    if (slug == "nsfw") return <MaterialIconOutlined className={className}>tag</MaterialIconOutlined>

    return <MaterialIcon className={className}>person</MaterialIcon>
}