import React from "react";
import { PItemProps } from "./PMain";
import { EnhanceText, EnhanceTextParams } from "../utils/EnhanceText";


export type PTextProps = PItemProps<string, EnhanceTextParams>
export default ({ content, ...setting }: PTextProps) => {
    let newText = content.split('\n').map((item, i) => {
        if (item === "") return <div key={`text-${i}`} className={`mt-2`} />;
        item = EnhanceText(item, setting);
        return <p key={`text-${i}`}>{item}</p>;
    });
    return (<>{newText}</>);
}