import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import serviceWorkerRegistration from './utils/ServiceWorker/serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if(localStorage.getItem("sw"))

if (process.env.NODE_ENV != "development" && location.host != "localhost:2052") serviceWorkerRegistration();