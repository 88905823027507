import { useState, useEffect } from "react";
import { SocketType } from ".";
import { SocketProxy } from "./utils/SocketProxy";

export const useClientSocket = () => {
    const [socket, setSocket] = useState<SocketType>();

    useEffect(() => {
        const s = new SocketProxy({
            reconnection: true,
            reconnectionDelay: 30000,
            path: "/api/io",
            auth: {
                token: window.version
            },
        });

        setSocket(s as any)

        return () => {
            if (!s) return
            s.disconnect();
        };
    }, []);

    useEffect(() => {

    }, [])

    return socket;
};
