import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive'
import "./index.scss";
import Footer from './components/footer';

import Menu from "../../components/mobile/menu/index";
import { Wrapper } from '../../components/wrapper';
import { HeaderDesktop } from '../../components/desktop/header';
import BarMessage from '../../components/styleguide/components/legacy/barmessage';
import cn from 'classnames';

const Bodyroot: React.FC<React.PropsWithChildren> = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isNano = useMediaQuery({ maxWidth: 512 });
    const isReNano = useMediaQuery({ maxWidth: 400 });
    const isNotMobile = useMediaQuery({ minWidth: 768 });

    const clases: string[] = [];

    if (isDesktop) clases.push("isDesktop")
    if (isTablet) clases.push("isTablet")
    if (isMobile) clases.push("isMobile")
    if (isNano) clases.push("isNano")
    if (isNotMobile) clases.push("isNotMobile")
    if (isReNano) clases.push("isReNano")

    return (
        <div id="content" className={cn(clases.join(" "), isMobile ? "min-h-[calc(100%-48px)]" : "min-h-[calc(100%-50px-2rem)]")}>
            {children}
        </div>
    );
}

function ResFooter() {
    const mScreen = new window.MScreen();
    if (mScreen.isMobile) return <Menu />;
    return <Footer />;
}

function VHeader() {
    const mScreen = new window.MScreen();
    if (mScreen.isMobile) return <Fragment>
        <BarMessage />
    </Fragment>
    return <Fragment>
        <HeaderDesktop />
        <BarMessage Container={({ children }: any) => {
            return (
                <div className='-mt-1 shadow-md'>
                    {children}
                </div>
            );
        }} />
    </Fragment>;
}

const Document: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return <Fragment>
        <Bodyroot>
            <VHeader />
            {/* <Header/> */}
            {children}
        </Bodyroot>
        <ResFooter />
    </Fragment>;
}

export default Document 