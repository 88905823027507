import React from "react"
import { ScrollChildren, ScrollingProps } from "../../../components/utils/Scrolling"
import { ConcatY } from "../../../components/styleguide/components/Concat"
import ItemShout from "../Shout/item-shout"
import { ScrollByNextId } from "./utils/ByNextId"
import { FindShouts } from "../../../hooks/apis/useShout"
import { IShout } from "../../../hooks/apis/usePost"
import { PlaceholderShouts } from "./utils/PlaceholderShouts"
import { useScrollingDelete } from "../../user/pages/Favoritos"
import { useForceUpdate } from "../../../components/utils/useForceUpdate"


export type ScrollShoutProps = Omit<React.ComponentProps<typeof ScrollByNextId>, "fetcher" | "initialSetting"> & {
    children?: ScrollChildren<IShout>,
    initialSetting: Parameters<typeof FindShouts>[0]
}
const Element: React.FC<ScrollShoutProps> = ({
    initialSetting,
    ...args
}) => {

    return <ScrollByNextId
        {...args}
        initialSetting={{
            __pageSize: initialSetting.count,
            __nextId: initialSetting.nextId,
        }}

        // demo="loading"

        fetcher={(params) => {
            return FindShouts({
                count: params.__pageSize,
                nextId: params.__nextId,
                ...initialSetting
            }).then((data) => {
                if (data === undefined || data.error) throw new Error(data.error)
                return {
                    newItems: data.data.list,
                    nextId: data.data.nextId,
                    hasMore: !data.data.isEnd
                }
            })
        }}
    />
}

export const ScrollShoutStyles = (count: number): Omit<ScrollingProps<any>, "_key" | "fetcher" | "children" | "id" | "initialSetting"> => ({
    btnRetryClass: "mt-4",
    loadingComponent: <PlaceholderShouts count={count} itemProps={{ panel: false }} />,
    loadingMoreComponent: <PlaceholderShouts count={2} className="mt-3" />
})

export const ScrollingDelete: React.FC<{ children: (params: { forceUpdate } & ReturnType<typeof useScrollingDelete>) => JSX.Element }> = ({ children }) => {
    const { del, hasDel } = useScrollingDelete()
    const { forceUpdate, cound } = useForceUpdate()
    return <React.Fragment key={cound}>
        {children({ forceUpdate, del, hasDel })}
    </React.Fragment>
}


const StylePanel = (props: Omit<ScrollShoutProps, "children">) => {
    const { del, hasDel } = useScrollingDelete()

    return <Element
        {...props}
        btnRetryClass="mt-4"
        loadingComponent={<PlaceholderShouts count={props.initialSetting.count} itemProps={{ panel: true, className: "rounded-none sm:rounded-md" }} />}
        loadingMoreComponent={<PlaceholderShouts count={2} className="mt-3" itemProps={{ panel: true, className: "rounded-none sm:rounded-md" }} />}
        children={((shouts: IShout[]) => (
            <ConcatY.forScroll>
                {shouts.filter(s => !hasDel(s._id)).map((shout) => <ItemShout key={shout._id} shout={shout} className="rounded-none sm:rounded-md" deleted={(s) => del(s._id)} />)}
            </ConcatY.forScroll>
        ))}
    />
}

const StyleNone = (props: Omit<ScrollShoutProps, "children">) => {
    const { del, hasDel } = useScrollingDelete()
    return <Element
        {...props}
        btnRetryClass="mt-4"
        loadingComponent={<PlaceholderShouts count={props.initialSetting.count} />}
        loadingMoreComponent={<PlaceholderShouts count={2} className="mt-3" />}
        children={(shouts: IShout[]) => (
            <ConcatY.withLine line={{ margin: "none", className: "my-2.5" }}>
                {shouts.filter(s => !hasDel(s._id)).map((shout) => <ItemShout key={shout._id} shout={shout} style='none' deleted={(s) => del(s._id)} ></ItemShout>)}
            </ConcatY.withLine>
        )}
    />
}

export const ScrollShout = Object.assign(Element, {
    StyleNone,
    StylePanel
})