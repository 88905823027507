import React, { useMemo, useRef, useState } from "react";
import { useResource, request } from "react-request-hook";

import { useCssHandles } from "../../../../hooks/useCssHandles";
import cn from "classnames";
import "./styles.scss";
import { useShoutTags } from "./context";
import SR from '../../../../components/utils/resources';
import { ApiType } from "../../../../hooks/apis/useApi";
import { useSWP } from "../../../../hooks/apis/useSWP";
import { LazyState } from "../../../../components/utils/LazyState";
import { useConstKey } from "../../../../hooks/useConstkey";
import { useLocation } from "react-router-dom";

export interface TagsTrendingResult {
    tag: string;
    count: number;
}

const useStyles = () => {
    return useCssHandles("tags", [
        "item"
    ] as const);
}

function PlaceholderItem() {
    const sizes = useRef({
        size0: Math.random() * 30
    });
    return <div className="lazy-loading mr3 dib" style={{
        width: `${30 + sizes.current.size0}px`,
        height: "20px",
        borderRadius: "3px"
    }} />;
}
function Placeholder() {
    const sizes = useRef({
        size1: Math.random() * 5
    });
    return <div className="flex-inline">
        {Array.from(Array(10).keys()).map((idx) => <PlaceholderItem key={idx} />)}
    </div>
}

const useTagsTrending = function () {
    useLocation()
    const stateKey = useConstKey("trending")
    const { data, isLoading } = useSWP<TagsTrendingResult[]>(["/api/shout/tags/trending", stateKey]);
    return [data, isLoading] as [typeof data, typeof isLoading]
}

export const ShoutTag: React.FC<{
    value: TagsTrendingResult,
    className?: string
}> = ({ value, className }) => {
    const styles = useStyles();
    const { tag, setTag } = useShoutTags() || {};

    const isNow = tag?.toLowerCase() === value.tag?.toLowerCase()

    return (
        <span data-tag={value.tag} className={cn("pointer", styles.item, className, { "zp1ou": isNow, "fNntB": !!!setTag })} onClick={() => {
            if (setTag) {
                setTag(isNow ? undefined : value.tag, "push")
            }
        }}>
            #{value.tag}{value.count ? ` (${value.count})` : ``}
        </span>
    );
}

export const TagsTrending: React.FC<{ className: string, minSize?: number }> = React.memo(({ className = "", minSize = 24 }) => {
    const styles = useStyles();
    const [tags, loading] = useTagsTrending();
    var CONTENT: any = <Placeholder />;
    const [open, setOpen] = useState(false)

    const tags_filter = useMemo(() => {
        if (open) return tags || []
        return (tags || []).slice(0, minSize)
    }, [tags, open])

    const hidden_tags = tags_filter.length !== (tags || []).length

    if (tags_filter) {
        if (tags_filter.length > 0) CONTENT = tags_filter.map(t => <ShoutTag key={t.tag} value={t} className={cn("mr3", "dib")} />)
        else CONTENT = <div className="center" style={{ color: "#aaa" }}>Nada por aqui</div>
    }

    return <div className={cn(className, styles.ROOT)}>
        {CONTENT}
        {hidden_tags && <a href="#" className="db" onClick={(e) => {
            e.preventDefault()
            setOpen(true)
        }}><span>Ver todas</span></a>}
    </div>
})

export const TagsTrendingView: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {}> = ({ className = "", ...args }) => {
    const styles = useCssHandles("tagView", ["clear", "tittle", "name"] as const);
    const { tag, setTag } = useShoutTags() || {};

    if (!tag) return null;

    return <div {...args} className={cn(className, "flex", "pv3 ph3", styles.ROOT)}>
        {/* <span className={cn(styles.tittle, "mr3")}>Etiqueta:</span>  */}
        <span className={cn(styles.name)}>#{tag}</span>
        {className?.includes("w-100") ? <div className="flex-grow-1" /> : <></>}
        <button
            className={cn(styles.clear, "pa2", "ml3")}
            onClick={() => setTag(undefined)}>
            {SR._icons._close3({ size: 14 })}
        </button>
    </div>
}