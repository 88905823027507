import { useEffect, useState } from "react"
import { useRefer } from "../../hooks/useRefer";
import { GlobalState } from "./LazyState";

export const useSignalUpdate = (name: string) => {
    const [cound, setCound] = useState(0);
    const refer = useRefer({ cound, setCound })
    const forceUpdate = () => refer.current.setCound(refer.current.cound + 1);

    useEffect(() => {
        const key = `sygnal-update-${name}`
        const list = GlobalState.get<(() => void)[]>(key)
        if (list) list.push(forceUpdate)
        else GlobalState.set<(() => void)[]>(key, [forceUpdate])
        return () => GlobalState.del(key)
    }, [name])

    return { forceUpdate, cound }
}

export function SignalUpdate(name: string) {
    const key = `sygnal-update-${name}`
    const update = GlobalState.get<(() => void)[]>(key)
    console.log(key, update)
    if (!update || update.length == 0) return;
    update.map(f => f())
}