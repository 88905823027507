import { encode_query } from "../../api";
import { BuildContextMemo } from "../../components/utils/BuildContextMemo";
import { useSWPN } from "../../hooks/apis/useSWP";
import { UserType } from "../../hooks/apis/useUser";
import { useConstKey } from "../../hooks/useConstkey";

const BUILD = ({ userId, username }: { userId: string, username: string }) => {
    const stateKey = useConstKey((userId || "") + "-" + (username || ""))

    const url = encode_query("/api/user/get", {
        ...userId ? { _id: userId } : { username: username },
        seguidores: 1
    })
    const reponse = useSWPN<{
        user: UserType,
        _unique: string,
        seguidores: any,
        siguiendo: any,
    }>([url, stateKey]);

    return {
        __deps: [reponse.data?._unique, reponse.isLoading],
        user: reponse.data?.user,
        seguidores: reponse.data?.seguidores,
        siguiendo: reponse.data?.siguiendo,
        reponse,
        historyKey: stateKey
    }
}

const CTX = BuildContextMemo(BUILD)
export const useProfile = CTX.useContext
export const ProfileProvider = CTX.Provider