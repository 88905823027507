import { SWRResponse, mutate } from "swr";
import useSWR_raw from 'swr/immutable'
import { serialize } from "../../api";
import { ApiType, useQuery } from "./useApi";
import { IShout } from "./usePost";
import { useNoNulled } from "../useNoNulled";

let __unique = 0
const fetcher = (url) => fetch(window.buildUrl(url)).then((res) => res.json().then(r => ({
    data: r,
    status: res.status
}))).then(i => {
    __unique++;
    if (i.data.data) i.data.data._unique = __unique
    return i
});

const ReturnSWR = <T = never, S = never>(args: SWRResponse<{
    data: ApiType<T> & { _unique?: string },
    status: number
}, S>) => {
    const { data: { data, error: errorA, _unique } = {}, status } = args?.data || {} as never;
    const { error: errorB, mutate } = args
    const error = errorA || errorB;
    return {
        data,
        mutate,
        status,
        _unique,
        isLoading: !error && !data,
        error,
    };
};

export const useSWP = <T>(deps: any[] = [], stg?: Parameters<typeof useSWR_raw>[2]) => {
    return ReturnSWR(
        useSWR_raw<{
            data: ApiType<T>,
            status: number
        }>(deps, fetcher, {
            ...stg as any,
        })
    );
};

export const useSWPN = <T>(deps: any[] = [], stg?: Parameters<typeof useSWP>[1]) => {
    const { data, mutate, _unique, isLoading, error, status } = useSWP<T>(deps, stg as any);
    const [dataNoNulled, isLoadingNoNulled] = useNoNulled(data, isLoading, [(data as any)?._unique])

    return {
        data: dataNoNulled,
        mutate,
        _unique,
        isLoading: isLoadingNoNulled,
        isRawLoaring: isLoading,
        error,
        status
    };
};