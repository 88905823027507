import React from "react";
import { useShout } from "../../item-shout"
import SR from "../../../../../components/utils/resources";
import { Link } from 'react-router-dom'
import Setting from "./setting";
import { Timer } from "../../../../../components/styleguide/components/Timer";

export default () => {
    let {
        originShout: shout,
        components: { ShoutLazyLoad }
    } = useShout();

    if (!shout) return null;

    const {
        author,
        created
    } = shout;

    const url_user = `/user/${author?._id}/${author?.username}`;

    return <div className="flex -mt-1 mb-2 relative">
        <ShoutLazyLoad unmountIfInvisible={true}>
            <Setting shout={shout} isShared={true} />
        </ShoutLazyLoad>
        <div className="line-clamp-1 w-[calc(100%-1.5rem)]">
            {SR._icons._shared({ size: 18, className: "fill-[#57a8ff] translate-y-[-2px] mx-1" })}
            <Link to={url_user}>
                <span className="text-[#00a2ff]">{author.name}</span>
            </Link>
            <span className="inline-block dark:bg-gray-500/75 bg-gray-400 rounded-full h-1 w-1 align-middle mx-1.5  " />
            <span className="username text-[14px] color_rosa">{author.username}</span>
            <span className="inline-block dark:bg-gray-500/75 bg-gray-400 rounded-full h-1 w-1 align-middle mx-1.5 mr-1 " />
            <Timer.withHover time={created} className=" text-xs text-gray-400" />
        </div>
    </div>;
}


// <Link to={url_user}>
// <Imagen pId="CtFzb96FNP" src={author?.photo} className="mr-2 photo-shared" alt={author?.username} style={{backgroundPosition:"center"}}/>
// </Link>