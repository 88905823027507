import React, { useMemo, useState } from "react";
import SR from '../../utils/resources';

import dayjs from "dayjs";
import 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat)

function get(time: string | Date | number) {
    return dayjs(time, { locale: "es" })
}

function format(time, { formet = "L LT", fromNow = false, ago = false } = {}) {
    const t = get(time)
    return fromNow ? t.fromNow(ago) : t.format(formet)
}

type FormatProps = {
    time: string | Date | number;
    className?: string
    formet?: string,
    fromNow?: boolean
    ago?: boolean
}
function Element({ time, className, formet = "L LT", fromNow, ago = false }: FormatProps) {
    const text = useMemo(() => {
        return format(time, { formet, ago, fromNow })
    }, [time])

    if (className) return <span className={className}>{text}</span>
    return <>{text}</>
}

type withHoverProps = {
    className?: string;
    time: string | Date | number;
    conteiner?: (content: JSX.Element) => JSX.Element;
    icon?: boolean;
    formet?: string
}
export function withHover({
    className,
    time,
    icon,
    conteiner,
    formet = "lll",
}: withHoverProps) {
    const [hover, setHover] = useState(false);

    const t = useMemo(() => {
        const t = get(time)
        return {
            fullTime: t.format(formet),
            fromNow: t.fromNow()
        }
    }, [time])

    return (
        <span
            className={className}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {icon && SR._icons._time2({ size: 20 })}{" "}
            {hover ? t.fullTime : (conteiner ? conteiner(<>{t.fromNow}</>) : t.fromNow)}
        </span>
    );
}

export const Timer = Object.assign(Element, {
    withHover,
    get,
    format
})