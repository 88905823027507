import React from 'react';


const Loading: React.FC<{ margin?: string }> = ({ margin }) => {
    return (
        <div className="componen_loading">
            <div className="conteiner" >
                <div className="lds-ellipsis" style={margin ? { margin: `${margin}px 0px` } : {}} ><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    );
}

export default Loading;