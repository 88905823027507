import React, { Suspense } from 'react';
import Rounting from './router';
// import { hot } from "react-hot-loader/root";
import "./utils/format"
import './scss/app.scss';
import './components/utils/mscreen'
import './api';
import { GTM_Init } from './utils/gtm';
import { ErrorBoundary } from './components/utils/ErrorBoundary';
import { ReactCrashed } from './pages/react-crashed';
import { LoaderNodeEnv } from './env';
// import 'bootstrap-grid';

GTM_Init()

declare global {
  interface Window {
    version: string
    page_name: string
    page_host: string
    readVersion: string
  }
}

window.version = require("../package.json").version;

function getVersion() {
  const [release, feature, patch] = window.version.split(".").map(e => parseInt(e));
  const abc = "abcdefghijklmnopqrstuvwxyz";
  return `v${release}.${feature}${abc[patch] || `-${patch}`}`
}

window.page_name = "Peluchan";
window.page_host = window.location.host;
window.readVersion = getVersion();

const PageLoading = () => {
  return (<div className="Ul1uoIs3R6 bg-black">
    {/* <div className="lds-ring "><div></div><div></div><div></div><div></div></div> */}
  </div>);
}

function App() {
  return (
    // <StrictMode>
    <ErrorBoundary ErrorComponent={ReactCrashed}>
      <LoaderNodeEnv>
        <Suspense fallback={<PageLoading />}>
          <Rounting />
        </Suspense>
      </LoaderNodeEnv>
    </ErrorBoundary>
    // <StrictMode>
  );
}
// if(process.env.NODE_ENV == "development") hot(App)
export default App;
