import cn from "classnames"
import React from "react"
import { Title } from "./Title";
import { PickTailwindSizeKeys } from "../utils/tailwindsize";
import { NavList } from "./Nav";

export const PanelStyles = {
    padding: "py-6 px-6",
    bg: "bg-[#FFF] dark:bg-[#1d2333]",
    text: "text-gray-500 dark:text-[#5b6d86]",
    nav: "text-gray-500 dark:text-[#5b6d86] ",
    border: "shadow-sm dark:shadow-none",
    // border: "border-solid border-[#e1e8ed] border-[1px] dark:border-none",
    rounded: "rounded-md",
    borderLine: "border-[#e1e8ed] dark:border-[#415468] border-solid border-0 border-b-[1px]"
}

const paddingSizeStyle: PickTailwindSizeKeys<"base" | "lg" | "xl" | "none"> & Record<"base-auto", string> = {
    "base-auto": "p-3  sm:p-4 ",
    base: "py-4 px-4",
    lg: "py-6 px-6",
    xl: "py-6 px-6",
    none: ""
}

const Element: React.FC<JSX.IntrinsicElements["div"] & { padding?: keyof typeof paddingSizeStyle, _ref?: React.LegacyRef<HTMLDivElement> }> = ({ className, _ref, padding = "base", children, ...args }) => {
    return <div ref={_ref} {...args} className={cn(PanelStyles.rounded, PanelStyles.border, PanelStyles.bg, className, paddingSizeStyle[padding])}>
        {children}
    </div>
}

const titleSizeStyle: PickTailwindSizeKeys<"base" | "lg" | "xl"> = {
    base: "text-[1.2rem] -mt-[0.3rem]",
    lg: "text-[1.65rem] -mt-[0.4rem]",
    xl: "text-[2rem] -mt-[0.4rem]",
}

const TitleLine = () => <div className={cn(PanelStyles.borderLine, "mb-4")}></div>
const TitleRaw: React.FC<Parameters<typeof Title>[0] & { titleSize?: keyof typeof titleSizeStyle }> = ({
    children,
    titleSize = "base",
    className,
    ...args
}) => {
    return <div className={cn(PanelStyles.text, titleSizeStyle[titleSize])}  >
        <Title {...args} className={cn("mt-[-0.25em] pb-1.5", className)}>{children}</Title>
        <TitleLine />
    </div>
}

export const withTitle: React.FC<JSX.IntrinsicElements["div"] & {
    titleSize?: keyof typeof titleSizeStyle
}> = ({
    children,
    titleSize = "base",
    title,
    ...args
}) => {
        return <Element {...args}>
            <TitleRaw titleSize={titleSize}>{title}</TitleRaw>
            {children}
        </Element>
    }

const navSizeStyle: PickTailwindSizeKeys<"base" | "lg" | "xl"> = {
    base: "text-[0.997rem] -mt-[0.3rem]",
    lg: "text-[1.446rem] -mt-[0.4rem]",
    xl: "text-[1.796rem] -mt-[0.4rem]",
}

const Nav: React.FC<Parameters<typeof NavList>[0] & { titleSize?: keyof typeof navSizeStyle, noTopFix?: boolean }> = ({ titleSize = "base", noTopFix, className, ...args }) => {
    return <NavList {...args} className={cn(PanelStyles.nav, navSizeStyle[titleSize], className)} classNameItem={cn({ ["mt-[-0.25em]"]: !noTopFix }, args.classNameItem)} />
}

export const withNav: React.FC<React.ComponentProps<typeof Element> & { nav: React.ComponentProps<typeof Nav> }> = ({
    children,
    nav,
    ...args
}) => {
    return <Element {...args}>
        <Nav {...nav} />
        {children}
    </Element>
}

export const withNavDeprecate: React.FC<React.ComponentProps<typeof Element> & { titleSize?: keyof typeof navSizeStyle, nav: Parameters<typeof NavList>[0]["children"] }> = ({
    children,
    titleSize = "base",
    nav,
    ...args
}) => {
    return <Element {...args}>
        <div className={cn(PanelStyles.nav, navSizeStyle[titleSize])} >
            <NavList >
                {nav}
            </NavList>
        </div>
        {children}
    </Element>
}
export const withNavLegacy: React.FC<React.ComponentProps<typeof Element> & { titleSize?: keyof typeof navSizeStyle, setting: Parameters<typeof NavList>[0]["children"] }> = ({
    children,
    className,
    titleSize = "base",
    setting,
    ...args
}) => {
    return <Element {...args} className={cn(className)}>
        <div className={cn(PanelStyles.nav, navSizeStyle[titleSize])} >
            <NavList >
                {setting}
            </NavList>
        </div>
        {children}
    </Element>
}


export const Panel = Object.assign(Element, {
    withTitle,
    withNav,
    withNavDeprecate,
    Nav,
    Title: TitleRaw,
    TitleLine,
    withNavLegacy,
    paddingSizeStyle,
    titleSizeStyle,
    navSizeStyle
})