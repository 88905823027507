import React, { useRef, useState } from 'react';
import { ShowResteasyError } from '../polls/PollCreator';
import { TimeLeftSelector } from '../polls/TimeLeftSelector';
import { refEnv } from '../../contexts/EnvironmentContext';
import SR from "../utils/resources";
import RemoteUpdate from "../utils/remoteupdate";

export const SendSticky: React.FC<{
    post: { _id: string }
    onSend?: (r: any) => void
}> = ({ post, onSend }) => {
    const refButton = useRef<HTMLButtonElement>();
    const [duration, setDuration] = useState(60 * 24);

    return (
        <div className="modal fade show" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{`Añadir Sticky`}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                            (window as any).popup.close();
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h5 className="modal-title mb1">Duración del sticky</h5>
                        <p className="mb3 gray">Luego de este tiempo el sticky desaparecerá automáticamente.</p>
                        <TimeLeftSelector min={5} max={60 * 24 * 7} value={duration} onChange={(v) => setDuration(v)} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                            (window as any).popup.close();
                        }}>Cancelar</button>
                        <button ref={refButton as any} type="button" className="btn btn-primary" onClick={async (e) => {
                            if (refButton.current) {
                                refButton.current.setAttribute("disabled", "1");
                                (window as any).forceUpdate.awjhgowagoiawga(true);
                            }
                            refEnv().api.LazySticky.create({
                                _id: post._id,
                                type: "Post" as any,
                                duration
                            }).then((result) => {
                                (window as any).popup.close();
                                if (onSend) onSend(result);
                                window.log("Sticky OK!", "green")
                            }).catch((e) => {
                                ShowResteasyError(e)
                                refButton.current.removeAttribute("disabled");
                                (window as any).forceUpdate.awjhgowagoiawga(false);
                            })
                        }}><RemoteUpdate _key="awjhgowagoiawga" content={(loading = false) => (
                            !loading ? "Enviar" : SR._icons._loading_dual_ring
                        )} /></button>
                    </div>
                </div>
            </div>
        </div>
    );
};