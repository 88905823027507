import React from "react";
import { Imagen } from "./Imagen";
import cn from "classnames";

export const ImagenRatio: React.FC<React.ComponentProps<typeof Imagen> & { wrapperClassName?: string, ratio?: number }> = React.memo(({ wrapperClassName, ratio = 1, className, ...props }) => {
    return (
        <div className={cn("relative", wrapperClassName)} style={{ paddingTop: (ratio * 100) + "%" }}>
            <Imagen {...props} className={cn("absolute inset-0 w-full h-full object-cover", className)} />
        </div>
    )
}) 