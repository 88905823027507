import cn from "classnames"
import { IPost } from "../../../../hooks/apis/usePost"
import React from "react"
import { Link } from "react-router-dom"
import { CategoryIconByName } from "../../../../components/utils/categoryIcons"
import { Timer } from "../../../../components/styleguide/components/Timer"

export type PostMetasProps = {
    /**
     * Muestra o esconde las vistas en las metas.
     * 
     * Defecto: ``FALSE``
     */
    showViews?: boolean,
    /**
     * Define si se deben de cortar las palabras en la categoria y el nombre.
     * 
     * Defecto: ``TRUE``
     */
    large?: boolean
}
export const PostMetas: React.FC<{ post: IPost, } & PostMetasProps> = ({ post, showViews, large = true }) => {
    let author = post.author
    if (!author) author = window.user404;

    return <span className={cn("nowrap h-[18px] text-[12px] line-clamp-1 mt-1 text-gray-300")}>
        {post?.category && post.category.name &&
            (<>
                <Link to={`/post/${post.category.slug}`} className=''>
                    <CategoryIconByName slug={post.category.slug} className='text-[14px] align-middle -mt-[2px]   ' />
                    <span className={cn("ml-[5px] truncate inline-block align-middle", large ? "max-w-[30%]" : "max-w-[20%]")}>{post.category.name}</span>
                </Link>
                <span className='inline-block w-[10px]' />
            </>)}

        <span className="material-icons align-middle text-[12px] -mt-[4px] mr-[5px]">schedule</span>
        <Timer fromNow ago time={post.created} />

        <span className='inline-block w-[10px]' />

        {author.name &&
            (<>
                <Link to={`/user/${author._id}/${author.username}`} className={cn("text-inherit truncate inline-block align-middle", large ? "max-w-[30%]" : "max-w-[20%]")}>
                    <span className="material-icons align-middle text-[12px] -mt-[3px] mr-[5px]">person</span>
                    <span>{author.name}</span>
                </Link>
                <span className='inline-block w-[10px]' />
            </>)}


        <span className="material-icons align-middle text-[11px] -mt-[4px] mr-[5px]">comment</span>
        {post.meta_comments}

        <span className='inline-block w-[10px]' />

        <span className="material-icons align-middle text-[12px] -mt-[4px] mr-[5px]">trending_up</span>
        {post.meta_puntos}

        {showViews && post.meta_vistas > 0 && (
            <>
                <span className='inline-block w-[10px]' />
                <span className="material-icons-outlined align-middle text-[12px] -mt-[4px] mr-[5px]">visibility</span>
                {post.meta_vistas}
            </>
        )}
    </span>
}