import React from "react"
import { IFav, FindFavs } from "../../../hooks/apis/useFavs"
import { ScrollByLastId } from "./utils/ByLastId"
import { ScrollCommentsStyles } from "./ScrollComment"
import { ScrollPostStyles } from "./ScrollPost"
import { ScrollShoutStyles } from "./ScrollShout"

export type ScrollFavsProps = Omit<React.ComponentProps<typeof ScrollByLastId>, "fetcher" | "children" | "initialSetting"> & {
    children: (items: IFav[]) => JSX.Element,
    initialSetting: _Favorite.Gets
}

export const ScrollFavs: React.FC<ScrollFavsProps> = ({
    children,
    initialSetting,
    ...args
}) => {
    return <ScrollByLastId
        {...args}
        children={children}

        {...initialSetting.type == "Comment" ? ScrollCommentsStyles(initialSetting.cound) : {}}
        {...initialSetting.type == "Shout" ? ScrollShoutStyles(initialSetting.cound) : {}}
        {...initialSetting.type == "Post" ? ScrollPostStyles(initialSetting.cound) : {}}

        initialSetting={{
            pageSize: initialSetting.cound,
            lastId: initialSetting.lastid,
            ...initialSetting
        }}

        fetcher={(params) => {
            return FindFavs({
                cound: params.pageSize,
                lastid: params.lastId,
                ...initialSetting
            }).then((data) => {
                if (data === undefined || data.error) throw new Error(data.error)
                return data.data.favs
            })
        }}
    />
}