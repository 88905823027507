import React, { useMemo, useRef } from "react"
import { refEnv } from "../../contexts/EnvironmentContext"
import { MediaParamTypes } from "../../contexts/EnvironmentContext/useMedia"
import { TempState } from "../utils/LazyState"

type HTMLVideoProps = React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>

type VideosProps = {
    pId: string,
    pParams?: MediaParamTypes,
    pDefault?: string,
    resetSize?: boolean,
    s2?: {
        variant: string
    },
    _ref?: React.LegacyRef<HTMLVideoElement>
}

export const VideoDefault404 = {
    Banner: "/resources/img404/notfoun-banner.svg",
    Box: "/resources/img404/notfoun-box.svg",
    nano2: "/resources/img404/notfoun-nano-2.svg",
    nano: "/resources/img404/notfoun-nano.svg",
    shouts: "/resources/img404/notfoun-shout.svg",
    shoutsTrans: "/resources/img404/notfoun-shout-trans.svg",
    generic: "https://mypay.com.my/image/staff/user.png"
}

const isDebug = localStorage.getItem("imagen-debug") === "true"

const resetVideo = (e, pDefault: string, resetSize: boolean) => {
    const default_url = pDefault || VideoDefault404.shouts
    if (default_url == e.currentTarget.src) return
    e.currentTarget.src = default_url
    if (resetSize) {
        e.currentTarget.style.width = "100%"
        e.currentTarget.style.height = ""
    }
}

const onErrorLocal = (e, redRetrys: { current: number }, props: HTMLVideoProps & VideosProps) => {
    const { pId, pParams, pDefault, resetSize = false, onError, ...args } = props

    if (refEnv().tag.is("notRetry")) {
        if (redRetrys.current == 0) return
        redRetrys.current = 0;
        return resetVideo(e, pDefault, resetSize)
    }

    try {
        if (onError) onError(e)
    } catch (error) {
        console.error(error)
    }

    redRetrys.current--;
    if (redRetrys.current <= 0)
        return resetVideo(e, pDefault, resetSize)

    try {
        const key = encodeURIComponent("img-fail-" + args.src)
        const urlFails: string[] = TempState.get(key) || []

        if (isDebug) console.error("Fail", e.currentTarget.src)
        e.currentTarget.setAttribute("data-fail", e.currentTarget.src)
        if (!urlFails.includes(e.currentTarget.src)) TempState.set(key, [...urlFails, e.currentTarget.src])
        const newUrl = refEnv().media(pId, args.src, pParams)

        if (urlFails.includes(newUrl) || newUrl == e.currentTarget.title)
            return resetVideo(e, pDefault, resetSize)
        if (isDebug) e.currentTarget.title += `\n🔹${urlFails.length + 2} - ` + newUrl
        e.currentTarget.src = newUrl
    } catch (error) {
        // console.error(error)
    }
}

export const Video: React.FC<HTMLVideoProps & VideosProps> = React.memo((props) => {
    const { pId, pParams, pDefault, _ref, onError, ...args } = props

    const redRetrys = useRef(5)
    const url = useMemo(() => {
        if (!args.src) return pDefault || VideoDefault404.shouts
        return refEnv().media(pId, args.src, pParams)
    }, [args.src, pId])

    if (isDebug) args.title = pId + "\n🌐" + args.src + "\n🔹1 - " + url
    return <video ref={_ref} data-pid={pId} {...args} src={url} onError={(e) => onErrorLocal(e, redRetrys, props)} />
})