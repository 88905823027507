import React from "react";
import cn from "classnames";
import { Timer } from "../../../../../../components/styleguide/components/Timer";

const RAREZA_COLOR = ["#FFF", "#AEE5FF", "#BEFFB5", "#8ACCFF", "#FFB4B4", "#FFC387"]
const RAREZA_NAME = ["", "Comun", "Raro", "Epico", "Mitico", "Legendario"]
export const MedallaInfo: React.FC<React.SVGProps<SVGSVGElement> & {
    title: string,
    description: string,
    created: string | undefined,
    active: boolean,
    rareza?: number,
    borde1?: string
}> = ({
    title,
    description,
    created,
    active,
    rareza = 0,
    borde1
}) => {

        const accent1 = borde1 || RAREZA_COLOR[rareza];

        return (
            <div className={cn("pa3", { "o-50": !active })}>
                <h4 style={{ color: accent1 }}>
                    {title}
                </h4>
                {description ? (
                    <div className="f6 mt2">
                        {description}
                    </div>
                ) : null}
                <div className="flex mt3">
                    <h6 className="mr5" style={{ color: accent1 }}>
                        {RAREZA_NAME[rareza]}
                    </h6>
                    <div className="flex-grow-1" />
                    <h6 className="gray">
                        Desde {created ? <Timer formet="DD MMM [de] YYYY" time={created} /> : ""}
                    </h6>
                </div>
            </div>
        );
    }