import React, { useEffect } from 'react';

function NavigationComponent() {
    useEffect(() => {
        function handleKeyPress(event) {
            const tagName = event.target.tagName.toLowerCase();

            // Verificar si el evento proviene de un campo de entrada
            if (tagName === 'input' || tagName === 'textarea' || tagName === 'select') {
                return; // No hacer nada si el evento proviene de un campo de entrada
            }

            // Verificar qué tecla se presionó
            switch (event.key) {
                case 'Home':
                    // Acción para la tecla 'Inicio'
                    window.scrollTo(0, 0); // Scroll al inicio de la página
                    break;
                case 'End':
                    // Acción para la tecla 'Fin'
                    window.scrollTo(0, document.body.scrollHeight); // Scroll al final de la página
                    break;
                case 'PageUp':
                    // Acción para la tecla 'Repág'
                    window.scrollBy(0, -window.innerHeight); // Desplazar la ventana hacia arriba
                    break;
                case 'PageDown':
                    // Acción para la tecla 'Avpág'
                    window.scrollBy(0, window.innerHeight); // Desplazar la ventana hacia abajo
                    break;
                // Agregar más casos según sea necesario para otras teclas
                default:
                    break;
            }
        }

        // Agregar un event listener para el manejo de las teclas presionadas
        window.addEventListener('keydown', handleKeyPress);

        // Limpiar el event listener cuando el componente se desmonte
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return null
    // return (
    //   <div>
    //     {/* Contenido de tu componente de navegación */}
    //   </div>
    // );
}

export default NavigationComponent;
