import { HOST } from "../../api";

export type UploadResponseType = {
    status_txt: string
    status_code: number
    data: {
        url: string
        format: string
        width: number
        height: number
        size: number
        thumb: string
        delete: string
        by: string
    }
    by: string
}

// Función para convertir una imagen base64 en un archivo Blob
export function base64ToBlob(base64) {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}



// Client-side (JavaScript)
async function uploadFile({ file, loading, filename }: { file?: any, filename?: string, loading?: () => any } = {}) {
    if (file == undefined) {
        // Crear un input de tipo file y agregarlo al DOM
        let input = document.createElement('input');
        input.type = 'file';
        input.style.display = "none"
        document.body.appendChild(input);

        // Simular un clic en el input
        input.click();

        // Esperar a que el usuario seleccione un archivo
        await new Promise(resolve => input.onchange = resolve);

        document.body.removeChild(input)

        // Verificar si el usuario seleccionó un archivo
        if (input.files.length == 0) {
            window.log('No se seleccionó ningún archivo')
            throw new Error('No se seleccionó ningún archivo')
        }

        file = input.files[0];
    }

    let formData = new FormData();
    formData.append("files", file);

    if (loading) loading()

    let res = await fetchPostFile(HOST + "/proxy/upload", formData);
    if (res.status_code !== 200) {
        window.log(res.status_txt, "red")
        throw new Error(res.status_txt)
    }
    return res as UploadResponseType;
}


// Fetch helper function
export async function fetchPostFile(url, formData) {
    try {
        let result = await (
            await fetch(url, {
                method: "POST",
                body: formData,
            })
        ).json();
        return result;
    } catch (err) {
        return err;
    }
}

export const PeluchanUpload = uploadFile