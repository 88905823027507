import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = ({ color, ...props }: SVGProps<SVGGElement> & {
    color: string
}) => (
    <>
        <g
            {...props}
            transform={`translate(5 1), scale(.15 .15), rotate(0)`}
        >
            <g>
                <g>
                    <polygon fill={color} points="15.008,97.797 43.407,109.63 39.679,103.713 41.938,97.797 33.554,96.13 22.5,92.963 " />
                    <polyline fill={color} points="31.941,87.084 33.145,91.047 33.554,92.463 24.77,89.463 26.583,82.797 " />
                    <polyline fill={color} points="49.5,86.38 76,67.13 100,85.63 82.667,102.63 59.839,109.63 47.787,110.297 51.159,103.13 
        49.352,94.463 "/>
                    <path fill={color} d="M28.104,77.797l0.621-16.507l-6.515-9.392l-0.776-22.323l0.921-5.919c0,0,6.191-8.631,9.27-10.163
        c4.325-2.151,15.891-3.083,20.206-0.912c3.778,1.9,9.464,12.462,9.464,12.462L65.132,38.5l-0.98,12.473l-8.343,12.99l0.812,7.333
        l-0.174,5l19.387-15.725l8.333,8.225l-2.5-12.765l-3.333-12.5L79.5,30.316l2.667-9.708l-5-9.277l-10.5-11.239
        c0,0-5.49-5.791-7.722-7.2c-3.557-2.245-9.136-3.603-16.436-3.603c-5.987,0-16.317,5.6-16.317,5.6l-9.113,9.53l-3.711,11.178
        l1.094,14.718l-0.672,13.216l5.41,8.003l-3.462,7.005l-1.074,7.756l1.134,2.667"/>
                    <path fill={color} d="M25.079,30.38c0,2.071-0.135,8.205-0.135,8.205l3.118,6.71c0,0,3.241,4.014,4.832,4.277
        c1.214,0.2,4.539-1.907,4.539-1.907l1.427-5.1l0.606-8.876c0,0-1.57-4.224-2.579-5.297c-0.788-0.838-4.007-2.262-4.007-2.262
        l-3.55,1.397"/>
                    <polyline fill={color} points="43.908,29.334 43.488,36.725 46.461,42.731 50.864,46.238 56.422,44.911 59.383,40.162 
        60.082,34.151 59.383,30.38 54.526,26.955 47.787,24.723 "/>
                    <polyline fill={color} points="34.365,62.13 39.546,59.825 45.555,59.825 50.241,62.13 50.487,70.63 43.985,69.297 38.218,69.463 
        33.748,71.297 33.558,63.297 "/>
                    <ellipse fill={color} cx="42.166" cy="76.851" rx="9.962" ry="3.075" />
                    <polyline fill={color} points="36.207,87.63 42.022,88.803 41.165,94.909 " />
                    <polyline fill={color} points="13.367,68.797 1.833,87.463 12.142,96.797 22.758,88.047 23.701,80.713 15.008,73.776 " />
                </g>
            </g>
        </g>
    </>
)

export default ({ borde1, backgroundShadow1 }: SVGProps<SVGGElement> & {
    borde1: string,
    backgroundShadow1: string
}) => (
    <>
        <g transform="translate(-2.7 3.5),  rotate(-5)" >
            <g transform="translate(1.4 1.5)" >
                <Icon color={backgroundShadow1} />
            </g>
            <Icon color={borde1} />
        </g>
    </>
)
