import React, { useRef, useState } from 'react';

import EditorList from "../EditorList/editorlist";

const SendDelete: React.FC<{
    title: string,
    preview: (msg: string) => string | JSX.Element,
    jsxHeader?: string | JSX.Element,
    jsxFooter?: string | JSX.Element,
    _delete: (msg: string) => void,
    buttonText?: string
    requered?: boolean
}> = (props) => {
    const { title, preview, _delete, requered = true } = props;

    const refEditorlist = useRef<EditorList | null>(null);
    const [text, setText] = useState<string>();

    const onClose = () => (window as any).popup.close();
    const onSend = () => {
        if (!_delete) return
        if (text) _delete(text);
        else {
            if (requered == false) _delete(undefined)
        }
        (window as any).popup.close();
    }

    //var body = null;
    return (
        <div className="modal fade show" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {props.jsxHeader}

                        <EditorList ref={(e) => refEditorlist.current = e} editor="text" placeholder={`por ...`} onChange={(body: { content: string }) => {
                            setText(body.content)
                        }}></EditorList>
                        <div className="mt-2"></div>
                        {text ? preview(text) : <>
                            {requered == false && <span className='f6' style={{ color: "#Fa0", }}>
                                ⚠️ No es obligatorio añadir algo.
                            </span>}
                        </>
                        }

                        {props.jsxFooter}

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>Cancelar</button>
                        <button disabled={(!text?.length || text?.length === 0) && requered == true} type="button" className="btn btn-primary" onClick={onSend} >{props.buttonText || "Eliminar"}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendDelete;