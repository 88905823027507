import React from 'react';
import EditorList from "../EditorList/editorlist";
import Ajax from "../utils/ajax";
import Loading from "../utils/loading";
import { Seguidores } from '../../pages/user/components/Seguidores';

class _showfallow_ extends React.Component {
    constructor() {
        super();
        this.message = {};
    }

    render() {
        const { _id, username } = this.props.user ? this.props.user : {};
        const { fallow_me = false } = this.props;
        const _this = this;
        const { message } = this;
        //var body = null;
        return (
            <div className="modal fade show" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{`Seguidores de @${username}`}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                window.popup.close();
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <Ajax type={"post"} path={`/api/user/${fallow_me ? `fallow_me` : `fallows`}`}
                                data={fallow_me ? {
                                    _id: _id,
                                    type: "user",
                                    count: 1000
                                } : {
                                    user_id: _id,
                                    count: 1000
                                }}
                                loading={() => (
                                    <Loading margin="60"></Loading>
                                )} err={(e) => (
                                    <h1>Error... {e.toString()}</h1>
                                )} ok={(r, s) => {
                                    if (r === undefined || r.data === undefined || r.error) return (<h1>Error</h1>);
                                    const { fallows } = r.data;

                                    return <Seguidores items={(fallows || []).map(i => fallow_me ? i.user : i.to)} />;
                                }} />

                        </div>
                        {/* <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{
                        window.popup.close();
                    }}>Aceptar</button>
                </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default _showfallow_;