//@ts-ignore

import React, { lazy } from "react";
import type { PStringHTMLProps } from "./PStringHTML";
import PText, { PTextProps } from "./PText";
import PImage, { PImageProps } from "./PImage";
import type { PPollProps } from "./PPoll";
import PDelete, { PDeleteProps } from "./PDelete";
import type { PMediaProps } from "./PMedia";
import PList, { PListProps } from "./PList";
import PShout, { PShoutProps } from "./PShout";
import PLink, { PLinkProps } from "./PLink";
// import { refPeluchan } from "../../../../contexts/PeluchanContext";
import PStringHTML from "./PStringHTML";

// const PStringHTML = lazy(() => import("./PStringHTML"));
// const PText = lazy(() => import("./PText"));
// const PImage = lazy(() => import("./PImage"));
const PPoll = lazy(() => import("./PPoll"));
// const PDelete = lazy(() => import("./PDelete"));
const PMedia = lazy(() => import("./PMedia"));
// const PList = lazy(() => import("./PList"));
// const PShout = lazy(() => import("./PShout"));
// const PLink = lazy(() => import("./PLink"));

export type IBody<T extends string, C = unknown> = {
    type: T,
    content: C
}

export type PItemProps<B extends any, S extends Record<string, unknown>> = { content: B } & S

type InferProps<A extends string, T extends PItemProps<any, Record<string, unknown>>> = { _type?: A, body: IBody<A, T["content"]> } & Omit<T, "content">

type PMainAll = {
    "string/html": PStringHTMLProps,
    "text": PTextProps,
    "delete": PDeleteProps,
    "image": PImageProps,
    "list": PListProps,
    "media": PMediaProps,
    "link": PLinkProps,
    "shout": PShoutProps,
    "poll": PPollProps,
    "any": Partial<PStringHTMLProps & PTextProps & PDeleteProps & PImageProps & PListProps & PMediaProps & PLinkProps & PShoutProps & PPollProps> & { content: unknown }
}

// type PMainProps<T extends keyof PMainAll> = PMainAll[T]

export function PMain<T extends keyof PMainAll>({ body, _type, ...params }: InferProps<T, PMainAll[T]>) {
    if (typeof (body.content) === "string") {
        if (body.type === "string/html") return <PStringHTML content={body.content} {...params as any} />;
        if (body.type === "text") return <PText content={body.content} {...params as any} />;
        if (body.type === "delete") return <PDelete content={body.content} {...params as any} />;
    }

    if (typeof (body.content) === "object") {
        if (body.type === "image") return <PImage content={body.content} {...params as any} />;
        if (body.type === "list") return <PList content={body.content} {...params as any} />;
        if (body.type === "media") return <PMedia content={body.content} {...params as any} />;
        if (body.type === "link") return <PLink content={body.content} {...params as any} />;
        if (body.type === "shout") return <PShout content={body.content} {...params as any} />;
        if (body.type === "poll") return <PPoll content={body.content} {...params as any} />;
    }

    return null;
}
