import React, { Fragment } from 'react';
import { createPopper } from '@popperjs/core';
import { Link } from 'react-router-dom';
import { refHistory } from '../../hooks/useHistory';

class _tooltip_ extends React.Component {
    // static defaultProps = {show: true}

    Init(root) {
        const {
            placement = 'bottom',
            offset = [0, 0],
            mode = "click",
            children
        } = this.props;
        if (!root) return;
        this.tooltip_id = "tooltip_" + parseInt(Math.random() * 100000);

        const more_button = root.querySelector(`.more`);
        const more_tooltip = root.querySelector(`div.dropdown-menu`);

        let popperInstance = null;

        function create() {
            popperInstance = createPopper(more_button, more_tooltip, {
                //strategy: 'fixed',
                placement: placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: offset,
                        },
                    }]
            });
        }

        function destroy() {
            if (popperInstance) {
                popperInstance.destroy();
                popperInstance = null;
            }
        }

        let showEvents = [];
        let hideEvents = [];

        if (mode === "hover") {
            showEvents = ['mouseenter', 'focus2'];
            hideEvents = ['mouseleave'];
        }
        if (mode === "click") {
            showEvents = ['click2', 'touchend2', 'focus2'];
            hideEvents = ['focusout', 'blur2'];
        }

        const _this = this;


        function hide(event) {
            if ("click" === mode && event) {
                const newTarget = event.relatedTarget;
                if (more_button.contains(newTarget) || more_tooltip.contains(newTarget)) return;
            }

            if (_this.stateshow) {
                _this.stateshow = false;
                if (_this.props.hidden) _this.props.hidden();
            }
            more_tooltip.removeAttribute('data-show');
            destroy();
        }
        this.hide = hide;

        function show() {
            if (_this.stateshow && "click" === mode) {
                hide();
                return;
            }
            if (_this.ms && _this.ms.isMobile) {
                window.menupopup(_this.GetContent({ list: children, force: true }), { title: _this.props.title, dom_offset: _this.props.menupopup });
                return;
            }

            more_tooltip.setAttribute('data-show', '');
            create();
            if (!_this.stateshow) {
                _this.stateshow = true;
                if (_this.props.show) _this.props.show();
            }

        }
        _this.show = show;

        showEvents.forEach(event => {
            more_button.addEventListener(event, show);
            more_tooltip.addEventListener(event, show);
        });

        hideEvents.forEach(event => {
            more_button.addEventListener(event, hide);
            more_tooltip.addEventListener(event, hide);
        });

    }

    GetContent({ list, _this, force = false }) {
        if (!force) {
            _this.ms = new window.MScreen();
            if (_this.ms.isMobile) return null;
        }

        return (
            list ?
                list.map((e, i) => {
                    const { text, show = true, panel, icon = null } = e;
                    const click = e.click ? (m) => {
                        e.click(m);
                        if (window.menupopupclose) window.menupopupclose();
                        return null;
                    } : () => {
                        if (e.to) refHistory().navegate(e.to);
                    };

                    if (!show) return null;
                    if (panel) return <Fragment key={i}>{panel}</Fragment>;

                    let _icon = null;
                    if (icon) {
                        _icon = (
                            <div className="icon25">{icon}</div>
                        );
                    }

                    if (e.to) {
                        return <Link key={text} to={e.to} onClick={() => { if (window.menupopupclose) window.menupopupclose(); }}><button style={{ textDecoration: "none !important" }} className={`dropdown-item  ${icon ? "icon24" : ""}`} type="button">{_icon}{text}</button></Link>
                    }
                    return (
                        <button key={text} onClick={click} className={`dropdown-item  ${icon ? "icon24" : ""}`} type="button">{_icon}{text}</button>
                    );
                }) : null);
    }

    render() {
        //const _this = this;
        const { button
            ,
            icon = "more_vert",
            children,
            className,
            mode = "click",
        } = this.props;
        const { GetContent } = this;
        const _this = this;

        if (children) {
            var one = false;
            for (let index = 0; index < children.length; index++) {
                const element = children[index];
                if (element.show === undefined || element.show === true) one = true;
            }
            if (!one) {
                return null;
            }
        }

        return (
            <span ref={(e) => { _this.Init(e) }} className={`simple_tooltip ${className ? className : ""}`}>
                <span className="more" tabIndex={button ? "-1" : undefined} onClick={() => {
                    if (window.menupopupclose && window.menupopup_open) {
                        window.menupopupclose();
                        return;
                    }
                    if (_this.show) _this.show();
                }}>
                    {
                        button ? (button) : (
                            <span className="material-icons puntos" tabIndex="-1" aria-describedby="tooltip">
                                {icon}
                            </span>
                        )
                    }
                </span>
                <div className="dropdown-menu" role="tooltip" aria-labelledby="dropdownMenu2">
                    <GetContent _this={this} list={children} />
                </div>
            </span>
        );
    }
}

export default _tooltip_;