import { useRef, useMemo } from "react";

export const useNoNulled = <T = unknown>(data: T, isLoading: boolean, deps: any[]) => {

    const refData = useRef<typeof data>(undefined)
    const dataNoNulled = useMemo(() => {
        if (isLoading && refData.current) return refData.current;
        refData.current = data
        return refData.current
    }, deps)

    return [dataNoNulled, isLoading && !dataNoNulled] as const
}