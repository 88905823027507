import React from "react";
import { PItemProps } from "./PMain";

export type PDeleteProps = PItemProps<string, {}>
export default ({ content, ...setting }: PDeleteProps) => {
    if (content === "[my:delete]") return (
        <div className="gway5767gaw comment_delete_my">Comentario eliminado por el autor</div>
    );
    if (content === "[other:delete]") return (
        <div className="gway5767gaw comment_delete_other">Comentario eliminado</div>
    );
}