import { useMemo } from "react";

export function useHost() {
    return useMemo(() => {
        var host = window.location.origin.includes(":3000") ? "http://192.168.0.9:8989" : window.location.origin;
        host = host.replace(":2082", ":2052");
        return {
            contexts: {
                host
            }
        }
    }, []);
}
