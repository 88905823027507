import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = ({ color, ...props }: SVGProps<SVGGElement> & {
    color: string
}) => (
    <>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rect\xE1ngulo 4"
                    transform="translate(261.607 406.607)"
                    fill={color}
                    d="M0 0h15v15H0z"
                />
            </clipPath>
        </defs>
        <g
            {...props}
            data-name="like"
            transform={`rotate(-20 -1007.642 947.692)`}
            clipPath="url(#a)"
        >
            <path
                d="M262 420.566h2.584v-7.751H262Zm14.215-7.1a1.294 1.294 0 0 0-1.294-1.3h-4.08l.618-2.953a.932.932 0 0 0 .021-.2.973.973 0 0 0-.284-.686l-.689-.682-4.253 4.257a1.3 1.3 0 0 0-.377.913v6.461a1.294 1.294 0 0 0 1.294 1.29h5.814a1.293 1.293 0 0 0 1.19-.789l1.947-4.556a1.317 1.317 0 0 0 .092-.473v-1.233l-.007-.007Z"
                fill={color}
            />
        </g>
    </>
)

export default ({ borde1, backgroundShadow1 }: SVGProps<SVGGElement> & {
    borde1: string,
    backgroundShadow1: string
}) => (
    <>
        <g transform="translate(1.1 1)" >
            <Icon color={backgroundShadow1} />
        </g>
        <Icon color={borde1} />
    </>
)
