import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import {
    Route,
    BrowserRouter as Router,
    Routes,
    useParams,
} from 'react-router-dom'

import Loading from '../../components/utils/loading';
import { UserPanel } from './components/userpanel';
import { Seguidores } from "./components/Seguidores";
import ShowFallows from "../../components/modal/showfallows";
import { Wrapper, WrapperSizes } from '../../components/wrapper';
import { Medallas } from '../components/Medallas';

import { Panel } from '../../components/styleguide/components/Panel';
import cn from 'classnames';
import { MemoHeightProvider, WidthRead } from '../../hooks/useMemoHeight';
import { Memo } from '../../components/utils/Memo';
import { ProfileProvider, useProfile } from './context';
import { PageError } from '../pageError';
import { ProfileUserContent } from './pages/UserContent';
import { ProfileFavs } from './pages/Favoritos';


export const PanelOfProfile: React.FC<React.ComponentProps<typeof Panel>> = ({ className, ...args }) => {
    return <Panel {...args} padding='base-auto' className={cn('rounded-none sm:rounded-md', className)} />
}

const UserPagePanel: React.FC = () => {
    const { user, siguiendo, seguidores, historyKey } = useProfile()

    return (
        <>
            <PanelOfProfile>
                <UserPanel user={user} show_moderation={true}></UserPanel>
            </PanelOfProfile>
            {(!seguidores || seguidores.length === 0) && (!siguiendo || siguiendo.length === 0) ? null :
                <div className="mt5">
                    {seguidores.length === 0 ? null :
                        <PanelOfProfile key={user._id + "fallowMe"}>
                            <Panel.Nav>
                                {[
                                    {
                                        name: `Seguidores (${user.meta_seguidores})`,
                                        key: "fallow",
                                        active: true,
                                    },
                                    ...user.meta_seguidores < 32 ? [] : [{
                                        key: "ver_all",
                                        name: "Ver todos",
                                        left: true,
                                        click: () => {
                                            (window as any).popup.load(<ShowFallows user={user} fallow_me={true} />);
                                        }
                                    }]
                                ]}
                            </Panel.Nav>
                            <Seguidores items={seguidores.map(i => i.user)} />
                        </PanelOfProfile>
                    }

                    {siguiendo.length === 0 ? null :
                        <PanelOfProfile key={user._id + "fallow"} className="mt5">
                            <Panel.Nav>
                                {[
                                    { name: `Siguiendo (${user.meta_siguiendo})`, key: "fallow" },
                                    ...user.meta_siguiendo < 32 ? [] : [{
                                        key: "ver_all",
                                        name: "Ver todos",
                                        left: true,
                                        click: () => {
                                            (window as any).popup.load(<ShowFallows user={user} fallow_me={false} />);
                                        }
                                    }]
                                ]}
                            </Panel.Nav>
                            <Seguidores items={siguiendo.map(i => i.to)} />
                        </PanelOfProfile>
                    }
                </div>
            }
            <Medallas params={{ user_id: user._id, debs: [historyKey] }} conteiner={(children) => {
                return (
                    <div className=" mt5">
                        <PanelOfProfile>
                            <Panel.Nav>
                                {[
                                    {
                                        name: `Medallas`, key: "medallas",
                                    }
                                ]}
                            </Panel.Nav>
                            {children}
                        </PanelOfProfile>
                    </div>
                );
            }} />
        </>
    );
}



const Profile: React.FC = React.memo(() => {
    const { reponse } = useProfile()
    // if(status === 404) return (<Message title="404" margin={52} message={result.error}></Message>);
    // if(status !== 200) return (<Message title={status} margin={52} message={`${statusText}`}></Message>);

    if (reponse.isLoading) return <Loading margin="20"></Loading>;
    if (reponse.error) return <PageError message={reponse.error} status={reponse.status} />;

    return <>
        <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4 p-0 sm:p-4 md:p-0">
            <div className={`col-span-2`}>
                <UserPagePanel />
            </div>
            <div className="col-span-3 md:order-first mt-4 md:mt-0">
                <Routes>
                    <Route path={`favoritos/*`} element={<>
                        <ProfileFavs />
                    </>}>
                    </Route>
                    {/* exact path={`/user/${_id}/${username}`} */}
                    <Route path="/*" element={<>
                        <ProfileUserContent />
                    </>}>
                    </Route>
                </Routes>
            </div>
        </div>
    </>
})

const MemoParams = () => {
    let { userId, username } = useParams();
    return <Memo debs={[userId, username]}>
        {() => (
            <ProfileProvider userId={userId} username={username}>
                <Profile />
            </ProfileProvider>
        )}
    </Memo>
}

const ProfilePage: React.FC = () => {
    return (
        <Wrapper max={WrapperSizes.W1000}>
            <Wrapper.Padded>
                <div className="page-user">
                    <MemoHeightProvider name="user" widthRefDom={".__shout__ "}>
                        <MemoParams />
                    </MemoHeightProvider>
                </div>
            </Wrapper.Padded>
        </Wrapper>
    );
}

export default ProfilePage;