import React from "react";

export type ErrorBoundaryUserProps = {
    error: Error,
    errorInfo?: unknown
}

export class ErrorBoundary extends React.Component<React.PropsWithChildren & {
    ErrorComponent: React.FC<ErrorBoundaryUserProps & Record<string, unknown>>
}, {
    hasError: boolean,
    errorProps: ErrorBoundaryUserProps
}> {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorProps: undefined };
    }

    componentDidCatch(error, errorInfo) {
        //   logErrorToService(error, errorInfo);
        this.setState({ hasError: true, errorProps: { error, errorInfo } });
    }

    render() {
        if (this.state.hasError) {
            const ErrorComponent = this.props.ErrorComponent
            return <ErrorComponent {...this.state.errorProps} /> || "Error";
        }
        return this.props.children;
    }
}