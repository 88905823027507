import { OptionsObject } from "notistack";
import { refEnv } from "../../contexts/EnvironmentContext";
import "./logs.css";

declare global {
    interface Window {
        log: typeof _log;
    }
}

const _log = (
    msg: string | object = "",
    className?:
        | string
        | OptionsObject<"default" | "error" | "success" | "warning" | "info">
) => {
    const value = ["string", "number"].includes(typeof msg)
        ? msg.toString()
        : JSON.stringify(msg);
    refEnv().notify.create(
        value,
        typeof className === "string"
            ? {
                className,
                autoHideDuration: 2700,
            }
            : {
                autoHideDuration: 2500,
                ...className,
            }
    );
};

window.log = _log;
