import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ImagenUsuario } from '../../components/Imagenes/Usuario';
import { UserType } from '../../../hooks/apis/useUser';

type SeguidorUser = Pick<UserType, "photo" | "_id" | "username">

type SeguidoresProps = {
    items: SeguidorUser[]
}
export const Seguidores: React.FC<SeguidoresProps> = ({ items }) => {
    const list = useMemo(() =>
        items
            .map((user) => ({
                _id: user._id,
                user: user,
                to: `/user/${user._id}/${user.username}`
            })
            ), [items]);

    return <div className='flex gap-2 flex-wrap'>
        {
            list.map((e, i) => {
                return (
                    <Link key={e._id} to={e.to}>
                        <ImagenUsuario.Small40 pId="s6dGwrklQ5" className='' src={e.user.photo} />
                    </Link>
                );
            })
        }
    </div>
}