import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Profile_Tooltip } from "../../../../../pages/user/components/usersesion";
import { useCssHandles } from "../../../../../hooks/useCssHandles";
import { MenuUserItem } from "./components/Items";
import { TooltipDesktop } from "../TooltipDesktop";
import "./index.scss";
import { HeaderTooltip, HeaderTooltipRaw } from "../../../../mui/Tooltip";
import { EditorShoutV2, EditorShoutData, SendShoutData } from "../../../../../pages/components/Shout/components/Editor/editor-shout";
import { LoadingButtonInShouts } from "../../../../mui/LoadingButton";
import RemoteUpdate from '../../../../utils/remoteupdate';
import SendIcon from '@mui/icons-material/Send';
import SR from '../../../../utils/resources';
import { useLocaleState } from "../../../../utils/useLocaleState";
import { SignalUpdate, useSignalUpdate } from "../../../../utils/useSignalUpdate";

type ProfileMenuProps = {
    user?: any
}

export const NanoIconShoutData: React.FC<{ x: number, y: number, color?: string }> = ({ x, y, color = "#ec5fa8" }) => {
    useSignalUpdate("NanoIconShoutData")
    if (!localStorage.getItem("shout-temp-v2")) return null;
    return (
        <span style={{
            "background": color,
            "borderRadius": "99px",
            "width": "8px",
            "height": "8px",
            "position": "absolute",
            "border": "2px solid #1d2333",
            "transform": `translate(${x}px, ${y}px)`
        }} />
    )
}



export const useMemoryShoutEditor = (_default: EditorShoutData | undefined = undefined, {
    memory = true
}: {
    memory?: boolean,
} = {}) => {
    const [value, setValue] = memory ?
        useLocaleState<EditorShoutData | undefined>("shout-temp-v2", _default) :
        useState<EditorShoutData | undefined>(_default);

    const clear = () => {
        setValue(undefined);
        setTimeout(() => SignalUpdate("NanoIconShoutData"), 1)
    }

    const onChange = (data: EditorShoutData) => {
        setValue(data);
        setTimeout(() => SignalUpdate("NanoIconShoutData"), 1)
    }

    return {
        value, setValue,
        clear,
        onChange
    }
}

const HeaderSendShout: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { value, clear, onChange } = useMemoryShoutEditor();

    return <EditorShoutV2
        onChange={onChange}
        value={value}
        disabled={loading}
        footerRight={(
            <div className="flex items-center">
                {value && <button title="Limpiar datos" className="shout-clears pa2 mr3" onClick={() => clear()}>{SR._icons._close3({ size: 14 })}</button>}
                <LoadingButtonInShouts
                    onClick={async () => {
                        if (!value) return;
                        setLoading(true);
                        const isOK = await SendShoutData(value);
                        await (new Promise(c => setTimeout(c, 1000)))
                        setLoading(false);
                        if (isOK) clear();
                    }}
                    endIcon={<SendIcon />}
                    loading={loading}
                    variant="contained"
                    loadingPosition="end"
                >
                    Enviar
                </LoadingButtonInShouts>
            </div>
        )}
    />
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ user = {} } = {}) => {
    const styles = useCssHandles("oXjalm27Ks", [
        "link"
    ] as const);

    return (
        <>

            <HeaderTooltip placement="bottom-end" title={<HeaderSendShout />}>
                <Link className={styles.link} to="#" onClick={(e) => {
                    e.preventDefault();
                }}>
                    <span >
                        <span className="material-icons">
                            add_box
                        </span>
                        <NanoIconShoutData x={-15} y={6} />
                    </span>
                </Link>
            </HeaderTooltip>

            {/* <Layout.HorizontalSpace mul={0.5}/> */}

            <TooltipDesktop />

            <HeaderTooltipRaw leaveDelay={0} title={"Mensajes"}>
                <Link className={styles.link} to={"/mensajes"}>
                    <span className="material-icons">
                        forum
                    </span>
                </Link>
            </HeaderTooltipRaw>


            <Profile_Tooltip user={user}>
                <MenuUserItem user={user} />
            </Profile_Tooltip>
        </>
    );
}