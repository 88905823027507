import cn from "classnames"
import React, { useState } from "react"

const placeHolderStyles = {
    default: "placeholder animate-pulse rounded-md bg-gray-200 dark:bg-[#2a3248]"
}

type PlaceholderPulseProps = React.HTMLAttributes<HTMLDivElement> & {
    styles?: keyof typeof placeHolderStyles;
};
const Element: React.FC<PlaceholderPulseProps> = ({ className, styles: style = "default", ...args }) => {
    return <div {...args} className={cn(placeHolderStyles[style], className)}></div>
}

const RangeSize: React.FC<PlaceholderPulseProps & { rangeWidth?: [number, number], rangeHeight?: [number, number] }> = ({ rangeWidth, rangeHeight, ...args }) => {
    const randomWidth = rangeWidth && useState(() => Math.floor(Math.random() * (rangeWidth[1] - rangeWidth[0] + 1)) + rangeWidth[0]);
    const randomHeight = rangeHeight && useState(() => Math.floor(Math.random() * (rangeHeight[1] - rangeHeight[0] + 1)) + rangeHeight[0]);

    return <Element {...args} style={{
        width: randomWidth ? `${randomWidth[0]}px` : "",
        height: randomHeight ? `${randomHeight[0]}px` : ""
    }} />;
}



export const PlaceholderPulse = Object.assign(Element, {
    RangeSize,
    placeHolderStyles
})
