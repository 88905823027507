import React, { useEffect, useMemo, useRef } from "react";
import { GlobalState, LazyState, TempState } from "../components/utils/LazyState";
import { useForceUpdate } from "../components/utils/useForceUpdate";
import { useRefer } from "./useRefer";
import { useDevice } from "../components/utils/mscreen";

const useClientWidthRef = ({ name, widthRefDom }: Parameters<typeof MemoHeightProvider>[0]) => {
    const { isMobile } = useDevice()
    if (widthRefDom == undefined) return { current: undefined }

    const { forceUpdate } = useForceUpdate();
    const clientWidthRef = useRef<number>()
    const clientWidthKey = name ? `clientWidth-${name}` : `clientWidth`
    const coundTrys = useRef(0)

    useMemo(() => {
        if (isMobile) return
        const clientWidth = TempState.get(clientWidthKey)
        if (clientWidth) clientWidthRef.current = clientWidth
    }, [name, widthRefDom])

    const refer = useRefer({ forceUpdate })

    function handler() {
        if (isMobile) return
        const trySave = TempState.get(clientWidthKey) == false
        const element = document.querySelector(widthRefDom)
        if (!element) {
            coundTrys.current++;
            if (coundTrys.current > 10) {
                throw new Error("El elemento no existe: " + widthRefDom)
            }
            return setTimeout(handler, 250)
        }
        const clientWidth = element.clientWidth
        if ((!clientWidth || clientWidthRef.current == clientWidth) && !trySave) return
        clientWidthRef.current = clientWidth
        TempState.set(clientWidthKey, clientWidthRef.current)
        refer.current.forceUpdate()
    }

    useEffect(() => {
        if (isMobile) return
        handler()
    }, [name, widthRefDom])

    useEffect(() => {
        window.addEventListener("resize", handler);
        return () => {
            window.removeEventListener("resize", handler);
        };
    }, [])

    if (isMobile) return { current: document.documentElement.clientWidth }

    return clientWidthRef
}

const useRootWidth = ({ name }: Parameters<typeof MemoHeightProvider>[0]) => {
    const { forceUpdate } = useForceUpdate();
    const { isMobile } = useDevice()
    const widthRef = useRef<number>()

    const clientWidthKey = name ? `clientWidth-${name}` : `clientWidth`

    useMemo(() => {
        if (isMobile) return
        const clientWidth = GlobalState.get(clientWidthKey)
        if (clientWidth) widthRef.current = clientWidth
    }, [name])

    if (isMobile) widthRef.current = document.documentElement.clientWidth
    return {
        widthRef,
        clientWidthKey,
        forceUpdate
    }
}

export const WidthRead: React.FC<{}> = () => {
    const { value } = React.useContext(CTX_MEMO_HEIGHT)

    return <div className="reference-width" ref={(e) => {
        if (!e) return
        if (value.widthRef.current == e.clientWidth) return
        value.widthRef.current = e.clientWidth
        GlobalState.set(value.clientWidthKey, e.clientWidth)
        value.forceUpdate()
    }} />
}


const CTX_MEMO_HEIGHT = React.createContext<ReturnType<typeof Build>>({} as any);

const Build = ({ name, widthRefDom }: Parameters<typeof MemoHeightProvider>[0]) => {
    //⚠️ Este useContext es para los shouts compartidos puedan acceder a un contexto ya existente del padre, no quitar.
    const { name: parentName } = (React.useContext(CTX_MEMO_HEIGHT) || {}) as any;
    const env = useRef({})

    const value = useRootWidth({ name, widthRefDom })

    return {
        name: (parentName ? `${parentName}:${name}` : name) as string,
        env,
        value
    }
}

export const MemoHeightProvider: React.FC<React.PropsWithChildren<{ name: string, widthRefDom?: string }>> = ({ children, ...args }) => {
    const values = Build(args)

    return React.useMemo(() => {
        return (
            <CTX_MEMO_HEIGHT.Provider value={values}>
                {children}
            </CTX_MEMO_HEIGHT.Provider>
        );
    }, [args.name]);
}

export function useMemoHeight(key) {
    const { name, env } = React.useContext(CTX_MEMO_HEIGHT) || {};
    const { forceUpdate } = useForceUpdate();

    const memoKey = name ? `${name}:${key}` : key

    const memo = LazyState.get(memoKey)

    function setMemoHeight(height) {
        if (!key) return false;
        if (LazyState.get(memoKey) === height) return false;
        LazyState.set(memoKey, height)
        forceUpdate()
        return true
    }

    return [memo, setMemoHeight, env]
}

export function useclientWidth() {
    return React.useContext(CTX_MEMO_HEIGHT).value?.widthRef?.current
}
