import React, { useLayoutEffect, useRef, useState, lazy, useEffect } from 'react';
import { Box } from '@mui/system';
import mergeWith from "lodash/mergeWith";
import isArray from "lodash/isArray";
import { CreatePollForBodyParser, initPoll, PollCreator } from '../../../../../components/polls/PollCreator';
import { useRefer } from '../../../../../hooks/useRefer';
import { randomstring } from '../../../../../components/utils/randomstring';
import { SwitchNano } from '../../../../../components/mui/Switch';
import { useSetterContent } from '../../../../../components/desktop/header/components/SetterContent';
import TagManager from 'react-gtm-module';
import { refPeluchan } from '../../../../../contexts/PeluchanContext';
import { DynImage } from './dyns/DynImage';
import { DynLink } from './dyns/DynLink';
import { DynMedia } from './dyns/DynMedia';
import { DynPoll } from './dyns/DynPoll';
import { TextShoutArea } from './TextShoutArea';
import { useLocaleState } from '../../../../../components/utils/useLocaleState';
import { ErrorBoundary } from '../../../../../components/utils/ErrorBoundary';

enum ItemNameType {
    Image = "image",
    Media = "media",
    Link = "link",
    Poll = "poll",
}
export type EditorShoutItemType<T> = {
    id: string,
    type: ItemNameType,
    content: T
};
export type EditorShoutData = {
    data: EditorShoutItemType<any>[],
    text: string | undefined,
    etiquetas: string[]
}
type EditorShoutV2Props = {
    disabled?: boolean,
    footerRight?: JSX.Element,
    defauld_setting?: any,
    onChange: (v: EditorShoutData) => void,
    value?: EditorShoutData
}

class ShoutDataUtil {
    static isMultimedia(value?: EditorShoutData) {
        if (!value) return false;
        return !!value?.data?.some(i => ["image", "media"].includes(i.type))
    }
    static isEmply(value?: EditorShoutData) {
        if (!value) return true;
        return (value.text || "").trim() == "" && value.data.length == 0;
    }
}

const useButtonNSFW = (value: EditorShoutData, setValue: (v: EditorShoutData) => void) => {
    const { tagsIncludes } = useSetterContent()
    const [isButtonNsfw, setNsfw] = useLocaleState("shout-nsfw-create-v2", false)
    const hasMultimedia = ShoutDataUtil.isMultimedia(value)
    const isShowButtonNswf = hasMultimedia && !tagsIncludes("nsfw")
    const isNsfw = isShowButtonNswf && isButtonNsfw

    const buildEtiquetas = () => {
        const list: string[] = []
        if (isNsfw) list.push("nsfw")
        return list
    }

    const ButtonNsfw = isShowButtonNswf && <SwitchNano checked={isButtonNsfw} labelClassName='!ml-0 !mr-2' onChange={e => setNsfw(v => !v)} label='NSFW' />

    useEffect(() => {
        if (!value) return
        const etiquetas = buildEtiquetas()
        if (JSON.stringify(etiquetas) != JSON.stringify(value.etiquetas))
            setValue({ ...value as any, etiquetas });
    }, [isNsfw, value])

    return {
        buildEtiquetas,
        ButtonNsfw,
        isShowButtonNswf,
        isNsfw
    }
}

export const EditorShoutV2Insafe: React.FC<EditorShoutV2Props> = ({
    disabled,
    footerRight,
    value,
    onChange: setValue,
    defauld_setting
}) => {
    const [cound, setCound] = useState(0);
    const ref = useRefer({
        value,
        setValue,
        cound,
        setCound
    })

    const nsfw = useButtonNSFW(value, setValue)

    const onUpdate = (v: Partial<EditorShoutData>) => {
        const newValue = Object.assign({
            data: []
        }, ref.current.value, v);
        const isEmpty = ShoutDataUtil.isEmply(newValue);
        if (isEmpty) ref.current.setValue(undefined)
        else ref.current.setValue(newValue)
    }

    const pushItem = (type: ItemNameType, content: any) => {
        const id = randomstring(24);
        onUpdate({
            data: [...(ref.current.value?.data || []), {
                id,
                type: type,
                content
            }]
        });
    }
    const updateItem = (id: string, new_content: any) => {
        let item = ref.current?.value?.data.find(i => i.id === id);
        if (!item || !ref.current) return;
        mergeWith(item.content, new_content, function (objA, objB) {
            if (isArray(objA)) {
                return objB;
            }
        });
        onUpdate(ref.current.value);
    }
    const deleteItem = (id: string) => {
        ref.current?.value?.data.splice(ref.current.value.data.findIndex(i => i.id === id), 1)
        onUpdate({
            data: [...(ref.current?.value?.data || [])],
        })
    }

    const existItems = (value?.data.length || 0) > 0;

    return (
        <div className="create-shout-content">
            <TextShoutArea disabled={disabled} value={value?.text || ""} onChange={(e) => onUpdate({ text: e.target?.value })} className="editor" placeholder="Comparte algo..." autoFocus />

            <div className="editor-content">
                {value?.data.map((obj, index) => {
                    if (!obj) return null;
                    const { type, content } = obj;
                    if (type === "image") return <DynImage key={obj.id} item={obj} setContent={v => updateItem(obj.id, v)} onDelete={() => deleteItem(obj.id)} defauld_setting={defauld_setting} />
                    if (type === "media") return <DynMedia key={obj.id} item={obj} setContent={v => updateItem(obj.id, v)} onDelete={() => deleteItem(obj.id)} />
                    if (type === "link") return <DynLink key={obj.id} item={obj} setContent={v => updateItem(obj.id, v)} onDelete={() => deleteItem(obj.id)} defauld_setting={defauld_setting} />
                    if (type === "poll") return <DynPoll key={obj.id} item={obj} setContent={v => updateItem(obj.id, v)} onDelete={() => deleteItem(obj.id)} />
                    // if(type === "shout") return <DynLink key={obj.id} dyn={obj} Delete={()=>{_this.delete(obj.id)}}/>
                    return null;
                })}
                <div className='flex justify-between py-0 px-1'>
                    <div>

                        {/* <SwitchNano checked={true} label='Blur'/> */}
                    </div>
                    {/* <div className='flex items-center bg-gray-900 p-0 rounded-md cursor-pointer'>
                        <span className="material-icons text-[12px] -translate-y-[1px] ">lock</span>
                        <span className="material-icons text-[12px] -translate-y-[1px]">arrow_drop_down</span>
                    </div> */}
                </div>
                <div className="editor-footer">
                    <Box className="toolbal" sx={{ display: "flex" }}>
                        <Box sx={{ flexGrow: 1, display: "flex" }}>
                            <button disabled={existItems || disabled} onClick={() => pushItem(ItemNameType.Image, { images: [] })} aria-label="Insertar/editar imagen" title="Insertar/editar imagen" type="button" className="tox-tbtn" aria-pressed="false"><span className="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24"><path d="M5 15.7l3.3-3.2c.3-.3.7-.3 1 0L12 15l4.1-4c.3-.4.8-.4 1 0l2 1.9V5H5v10.7zM5 18V19h3l2.8-2.9-2-2L5 17.9zm14-3l-2.5-2.4-6.4 6.5H19v-4zM4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1V4c0-.6.4-1 1-1zm6 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fillRule="nonzero"></path></svg></span></button>
                            <button disabled={existItems || disabled} onClick={() => pushItem(ItemNameType.Media, { url: null })} aria-label="Insertar/editar medio" title="Insertar/editar medio" type="button" className="tox-tbtn" aria-pressed="false"><span className="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24"><path d="M4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1V4c0-.6.4-1 1-1zm1 2v14h14V5H5zm4.8 2.6l5.6 4a.5.5 0 0 1 0 .8l-5.6 4A.5.5 0 0 1 9 16V8a.5.5 0 0 1 .8-.4z" fillRule="nonzero"></path></svg></span></button>
                            <button disabled={existItems || disabled} onClick={() => pushItem(ItemNameType.Link, { url: null })} aria-label="Insertar/editar enlace" title="Insertar/editar enlace" type="button" className="tox-tbtn" aria-pressed="false"><span className="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24"><path d="M6.2 12.3a1 1 0 0 1 1.4 1.4l-2.1 2a2 2 0 1 0 2.7 2.8l4.8-4.8a1 1 0 0 0 0-1.4 1 1 0 1 1 1.4-1.3 2.9 2.9 0 0 1 0 4L9.6 20a3.9 3.9 0 0 1-5.5-5.5l2-2zm11.6-.6a1 1 0 0 1-1.4-1.4l2-2a2 2 0 1 0-2.6-2.8L11 10.3a1 1 0 0 0 0 1.4A1 1 0 1 1 9.6 13a2.9 2.9 0 0 1 0-4L14.4 4a3.9 3.9 0 0 1 5.5 5.5l-2 2z" fillRule="nonzero"></path></svg></span></button>
                            <button disabled={existItems || disabled} onClick={() => pushItem(ItemNameType.Poll, { pollId: null })} aria-label="Insertar/editar enlace" title="Insertar/editar enlace" type="button" className="tox-tbtn" aria-pressed="false"><span className="tox-icon tox-tbtn__icon-wrap"><svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" style={{ width: "1.7em", height: "1.7em", verticalAlign: "middle", overflow: "hidden", }} viewBox="0 0 1024 1024"><path d="M256 469.333h682.667v85.334H256zm0-170.666h510.72V384H256zM256 640h384v85.333H256zM85.333 170.667h85.334v682.666H85.333z" /></svg></span></button>
                            {nsfw.ButtonNsfw}
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            {footerRight}
                        </Box>
                    </Box>
                </div>
            </div>
        </div>
    );
}

export const SendShoutData = async (body: EditorShoutData) => {
    try {
        // Creamos la poll antes de enviar el shout
        var poll = body.data.find(d => d.type === ItemNameType.Poll)
        if (poll) poll.content = await CreatePollForBodyParser(poll.content);
    } catch (error) {
        return false
    }

    let raw_type = "list";
    let raw = body.data;
    if (raw.length === 1) {
        raw_type = body.data[0].type;
        raw = body.data[0].content;
    }

    const shout_raw: {
        body: {
            content: any,
            type: string
        },
        text?: string,
        etiquetas?: string[]
    } = {
        body: {
            content: raw,
            type: raw_type
        },
        etiquetas: body.etiquetas
    };

    if (body.text) shout_raw.text = body.text;

    const { data, error } = await window.POST(`/api/shout/create`, {
        shout: shout_raw
    });

    if (error || (!data && !error)) {
        if (!data && !error) {
            window.log("Servidor no responde", "red");
            return false;
        }
        window.log(error, "red");
        return false;
    }

    window.log("El shout se publico correctamente", "green");

    TagManager.dataLayer({
        dataLayer: {
            event: "shout",
            shoutId: data._id,
            tags: data.etiquetas,
            username: refPeluchan().login.user.username
        }
    })

    return true;
}

export const EditorShoutV2: React.FC<EditorShoutV2Props> = (props) => {
    return <ErrorBoundary ErrorComponent={(e) => {
        localStorage.removeItem("shout-temp")
        return <>Error: {e.error.message}</>
    }}>
        <EditorShoutV2Insafe {...props} />
    </ErrorBoundary>
}
