import React from "react";
import { usePeluchan } from "../../../../../contexts/PeluchanContext";
import { useCssHandles } from "../../../../../hooks/useCssHandles";
import { NotificacionesDesktop } from "../NotificacionesDesktop";
import { HeaderTooltip } from "../../../../mui/Tooltip";
import Badge from '@mui/material/Badge';

const Notis = () => {
    const styles = useCssHandles("oXjalm27Ks", [
        "link",
        "num",
        "notis"
    ] as const);
    const { login: { notis: { count } } } = usePeluchan();
    return <span className={`${styles.link} ${styles.notis}`}>
        {count > 0 ? (<span className={styles.num}>{count}</span>) : null}
        <span className="material-icons">
            notifications
        </span>
    </span>;
}

export function TooltipDesktop() {
    const { login: { notis: { count } } } = usePeluchan();
    return <HeaderTooltip placement="bottom" title={<NotificacionesDesktop tooltip count={count} />}>
        <div>
            <Notis />
        </div>
    </HeaderTooltip>

    // return <Tooltip 
    //     key={user._id}  
    //     className="button_notificaciones" 
    //     button={<Notis/>} 
    //     placement={"bottom"} 
    //     offset={[0,8]} show={()=>{
    //         toCero();
    //         setView(true);
    //     }}
    //     hidden={()=>{
    //         setView(false);
    //     }}
    // >
    //     {[{ panel:<NotificacionesDesktop view={view} count={count}/>  }]}
    // </Tooltip>
}
