
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { Fragment } from 'react';
import { ClickAwayListener } from '@mui/material';

export const TextTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip disableFocusListener arrow enterDelay={500} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#272d32',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#272d32",
        color: '#cfe6ff',
        boxShadow: theme.shadows[1],
        maxWidth: "none"
    },
}));

export const DarkTooltip = styled(({ className, ...props }: TooltipProps & { stylearrow?: React.CSSProperties }) => (
    <Tooltip disableFocusListener disableTouchListener arrow {...props} classes={{ popper: className }} />
))(({ stylearrow: styleArrow, theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#1d2333',
        ...styleArrow
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#1d2333",
        color: '#cfe6ff',
        boxShadow: theme.shadows[1],
        maxWidth: "none"
    },
}));

export const MedallaTooltip = styled(({ className, ...props }: TooltipProps & {
    stylearrow?: React.CSSProperties,
    border1: string
}) => (
    <Tooltip disableFocusListener disableTouchListener arrow {...props} classes={{ popper: className }} />
))(({ stylearrow: styleArrow, theme, border1 }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: border1,
        ...styleArrow
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#1d2333",
        border: "1px solid " + border1,
        color: '#cfe6ff',
        boxShadow: theme.shadows[1],
        maxWidth: "none"
    },
}));
export const MedallaMobileTooltip: React.FC<React.PropsWithChildren<React.ComponentProps<typeof MedallaTooltip>>> = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    return <Fragment>
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <MedallaTooltip
                    {...props}
                    stylearrow={{
                        "right": "calc(50% - 4px)",
                        "left": "inherit !important",
                        "transform": "none !important",
                    }}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                >
                    <div onClick={handleTooltipOpen}>
                        {props.children}
                    </div>
                </MedallaTooltip>
            </div>
        </ClickAwayListener>
    </Fragment>
}

export const HeaderTooltipRaw = styled(({ className, ...props }: TooltipProps & { stylearrow?: React.CSSProperties }) => (
    <DarkTooltip enterDelay={50} leaveDelay={400} {...props} />
))(({ stylearrow: styleArrow, theme }) => ({
    [`.MuiTooltip-arrow`]: {
        ...styleArrow
    },
    [`& .${tooltipClasses.tooltip}`]: {
    },
}));

export const HeaderTooltip: React.FC<React.PropsWithChildren<TooltipProps>> = (props: TooltipProps) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    return <Fragment>
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <HeaderTooltipRaw
                    {...props}
                    stylearrow={{
                        "right": props.placement == "bottom" ? "calc(50% - 4px)" : "calc(0% + 13px)",
                        "left": "inherit !important",
                        "transform": "none !important",
                    }}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                >
                    <div onClick={handleTooltipOpen}>
                        {props.children as any}
                    </div>
                </HeaderTooltipRaw>
            </div>
        </ClickAwayListener>
    </Fragment>
}