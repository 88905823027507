
import React, { useMemo } from 'react';

import SR from "../../../components/utils/resources";
import Tooltip from "../../../components/utils/tooltip";
import ShowFavs from "../../../components/modal/showfavs";
import { usePeluchan } from '../../../contexts/PeluchanContext';
import { refEnv } from '../../../contexts/EnvironmentContext';
import SendBug from '../../../components/modal/sendbug';

//http://localhost:8989/api/post/search?count=20&soft=relevance

export function Profile_Tooltip({ children, user, mobile = false, menupopup = undefined }) {
    const { login: { outLogin, rol: { dios, mod } } } = usePeluchan();
    const { _id, username } = user;
    return (
        <Tooltip title={user.name} mode={mobile ? "click" : "hover"} menupopup={menupopup} placement={"bottom"} offset={[0, 0]} button={children}>
            {[
                {
                    text: "Perfil",
                    to: `/user/${_id}/${username}`,
                    icon: SR._icons._perfil
                }, {
                    text: "Buscador",
                    to: `/search`,
                    icon: SR._icons._Search({ size: 17 }),
                    show: mobile && refEnv().tag.is("buscador")
                }, {
                    text: "Admin",
                    to: `/admin`,
                    icon: SR._icons._historial,
                    show: dios || mod
                }, {
                    text: "Favoritos",
                    icon: SR._icons._favorite_list4({ size: 18 }),
                    to: `/user/${_id}/${username}/favoritos`
                }, {
                    text: "Mensajes",
                    to: `/mensajes`,
                    icon: SR._icons._mensajes,
                    show: mobile
                }, {
                    text: "Historial del sitio",
                    to: `/info/history`,
                    icon: SR._icons._historial,
                    show: mobile
                }, {
                    text: "Opciones",
                    to: `/user/${_id}/${username}/setting`,
                    icon: SR._icons._usersetting
                }, {
                    text: "Reportar bug",
                    icon: SR._icons._report_bug,
                    click: () => {
                        window.popup.load(<SendBug />)
                    }
                }, {
                    text: "Salir",
                    click: (e) => outLogin(),
                    icon: SR._icons._exit
                }
            ]}
        </Tooltip>
    );
}

export function Profile({ loading, err, ok, out }) {
    const { login: { status, raw, error } } = usePeluchan();

    function MyProfile() {
        switch (status) {
            case "loading": return loading(raw);
            case "ok": return ok(raw);
            case "out": return out(raw);
            case "err": return err(error);
            default: return err(error);
        }
    }
    return useMemo(() => <MyProfile />, [status, raw?.user?.photo, raw?.user?.name]);
}

