import cn from "classnames"
import { Imagen, ImgDefault404 } from "../../../components/Imagen/Imagen"
import React from "react"
import { useTheme } from "../../../contexts/ThemeContext"


const elementStyles = {
    primary: cn(
        "cursor-pointer rounded-md",

        "shadow-inner",
        "hover:outline outline-2 outline-blue-300/50",

        "dark:shadow-shadowClassic",
        "dark:hover:outline outline-2 dark:outline-blue-500/50",
    ),
    primaryRed: cn(
        "cursor-pointer rounded-md",

        "shadow-inner",
        "hover:outline outline-2 outline-blue-300/50",

        "dark:shadow-shadowClassicRed",
        "dark:hover:outline outline-2 dark:outline-[#ff006a50]",
    ),
    secondary: cn(
        "cursor-pointer rounded  outline-2",
        "outline outline-gray-300/10",
        "shadow-inner",
        "hover:outline  hover:outline-blue-300/50",

        "dark:hover:outline dark:outline-gray-500/30",
        "hover:outline dark:hover:outline-blue-500/50",
    )
}

const Element: React.FC<React.ComponentProps<typeof Imagen> & { styles?: keyof typeof elementStyles }> = ({ className, styles = "primary", ...args }) => {



    return <Imagen {...args}
        className={cn(
            elementStyles[styles],
            className
        )}
        pDefault={ImgDefault404.nano}
        resetSize={false}
        style={{
            backgroundPosition: "center",
            objectFit: "cover"
        }}
    />
}

const Small25: React.FC<React.ComponentProps<typeof Element>> = ({ className, ...args }) => {
    return <Element {...args} className={cn(" w-[25px] h-[25px]", className)} pParams={{ options: { s2: { varians: "profile.thumbnail" } } }} />
}

const Small32: React.FC<React.ComponentProps<typeof Element>> = ({ className, ...args }) => {
    return <Element {...args} className={cn(" w-[32px] h-[32px]", className)} pParams={{ options: { s2: { varians: "profile.thumbnail" } } }} />
}

const Small40: React.FC<React.ComponentProps<typeof Element>> = ({ className, ...args }) => {
    return <Element {...args} className={cn(" w-[40px] h-[40px]", className)} pParams={{ options: { s2: { varians: "profile.thumbnail" } } }} />
}

const Small50: React.FC<React.ComponentProps<typeof Element>> = ({ className, ...args }) => {
    return <Element {...args} className={cn(" w-[50px] h-[50px]", className)} pParams={{ options: { s2: { varians: "profile.thumbnail" } } }} />
}

const Small120: React.FC<React.ComponentProps<typeof Element>> = ({ className, ...args }) => {
    return <Element {...args} className={cn(" w-[120px] h-[120px]", className)} />
}

export const ImagenUsuario = Object.assign(Element, {
    Small25,
    Small32,
    Small40,
    Small50,
    Small120
})