import * as React from "react";
import { SVGProps, memo } from "react";

const Icon = ({
    color,
    ...props
}: SVGProps<SVGGElement> & {
    color: string;
}) => (
    <>
        <g {...props} transform={`translate(2.7 -1.7), scale(.17 .17), rotate(0)`}>
            <path
                fill={color}
                d="M79.055,71.445c3.844,4.598,7.68,9.203,11.543,13.786c0.479,0.568,0.414,0.815-0.239,1.14
			c-3.804,1.894-7.863,2.989-11.994,3.847c-5.296,1.099-10.658,1.689-16.052,2.026c-0.347,0.022-0.872-0.253-1.039,0.363
			c-7.188,0-14.376,0-21.564,0c-0.167-0.616-0.692-0.342-1.039-0.363c-6.122-0.377-12.203-1.073-18.195-2.409
			c-3.698-0.825-7.339-1.848-10.784-3.476c-0.597-0.282-0.845-0.475-0.305-1.105c5.32-6.202,10.614-12.425,15.917-18.642
			c0.042-0.049,0.1-0.083,0.151-0.125c2.35,0.757,4.637,1.691,6.99,2.449c7.877,2.535,15.941,4.169,24.188,4.622
			C64.187,73.974,71.713,73.535,79.055,71.445z"
            />
            <path
                fill={color}
                d="M50.196-14.16c-0.261,0.267,0.01,0.489,0.092,0.696c0.842,2.144,1.729,4.271,2.549,6.424
			c0.227,0.597,0.564,0.768,1.167,0.798c2.238,0.11,4.474,0.274,6.71,0.425c0.186,0.013,0.401-0.043,0.637,0.255
			c-0.458,0.384-0.922,0.773-1.386,1.161c-1.441,1.205-2.87,2.426-4.332,3.606c-0.423,0.341-0.51,0.646-0.369,1.177
			c0.642,2.413,1.227,4.842,1.878,7.445c-2.422-1.496-4.7-2.903-6.979-4.311c-0.11,0-0.22,0-0.331,0
			c-2.268,1.402-4.535,2.804-6.972,4.311c0.517-2.079,0.849-4.05,1.514-5.902c0.609-1.696,0.261-2.744-1.211-3.758
			c-1.59-1.096-2.998-2.456-4.53-3.738c0.341-0.338,0.715-0.241,1.044-0.264c2.17-0.151,4.341-0.293,6.512-0.425
			c0.386-0.023,0.681-0.084,0.848-0.509c0.936-2.39,1.897-4.771,2.842-7.158c0.023-0.058-0.05-0.154-0.079-0.233
			C49.932-14.16,50.064-14.16,50.196-14.16z"
            />
            <path
                fill={color}
                d="M68.067,45.367c4.24,5.161,8.48,10.321,12.719,15.482c0.919,1.119,0.924,1.136-0.401,1.804
			c-1.799,0.906-3.708,1.519-5.641,2.066c-0.335,0.095-0.727,0.06-0.973,0.384c-0.359,0.04-0.726,0.044-1.075,0.125
			c-6.282,1.456-12.681,1.859-19.094,2.033c-3.318,0.09-6.641,0.034-9.965-0.139c-3.517-0.184-7.02-0.438-10.502-0.943
			c-2.178-0.316-4.345-0.714-6.516-1.076c-0.948-0.547-2.043-0.643-3.059-0.986c-1.561-0.528-3.105-1.081-4.577-1.829
			c-0.522-0.265-0.557-0.496-0.181-0.95c5.404-6.515,10.793-13.043,16.186-19.567c0.568,0.257,1.151,0.486,1.701,0.777
			c5.035,2.656,10.45,3.985,16.083,4.32c3.506,0.208,7.045,0.216,10.534-0.384C64.911,46.206,66.584,46.181,68.067,45.367z"
            />
            <path
                fill={color}
                d="M49.833,8.991c0.11,0,0.22,0,0.331,0c2.305,3.289,4.601,6.584,6.917,9.866
			c4.443,6.296,8.898,12.584,13.346,18.876c0.641,0.907,0.645,0.94-0.416,1.234c-1.901,0.526-3.797,1.075-5.742,1.421
			c-2.564,0.626-5.174,0.935-7.793,1.233c-4.837,0.55-9.65,0.337-14.469-0.194c-2.029-0.223-4.008-0.776-6.056-0.849
			c-1.344-0.638-2.836-0.741-4.239-1.168c-0.629-0.192-1.264-0.371-1.908-0.503c-0.733-0.15-0.811-0.399-0.362-1.02
			c2.138-2.965,4.233-5.96,6.344-8.945c4.45-6.291,8.901-12.581,13.349-18.874C49.381,9.717,49.601,9.349,49.833,8.991z"
            />
            <path
                fill={color}
                d="M58.955,96.828c1.632,3.285,3.229,6.497,4.85,9.761c-8.867,0-17.65,0-26.652,0
			c1.595-3.219,3.188-6.432,4.821-9.726"
            />
        </g>
    </>
);

export default ({
    borde1,
    primary1,
    backgroundShadow1,
}: SVGProps<SVGGElement> & {
    borde1: string;
    primary1?: string;
    backgroundShadow1: string;
}) => (
    <>
        <g transform="translate(-2.7 3.9), scale(1.15 1), rotate(0)">
            <g transform="translate(1.4 1.5)">
                <Icon color={backgroundShadow1} />
            </g>
            <Icon color={primary1 || borde1} />
        </g>
    </>
);
