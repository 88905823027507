import type { } from "../../../../../node_ts/src/shared/schemas/favs.schema";
import { ApiType } from "./useApi";

export type IFav = {
    _id: string,
    type: ITypeFavs,
    to: any,
}

export type FindCommentsParams = {
    q?: string;
    post_id?: string;
    user_id?: string;
    list_id?: string[];
    list_global?: boolean;
    parent_type?: "Post" | "Shout" | "none";
    count: number;
    lastid?: string;
}
export const FindComments = (params: FindCommentsParams) => {
    return window.POST<ApiType<{
        comments: any[],
    }>>("/api/comment/gets", params);
}