import React from 'react';
import { Link } from 'react-router-dom';
import { ShoutTag } from '../pages/mi/components/TagsTrending';
//const reactStringReplace = require('react-string-replace-recursively');
const reactStringReplace = require('react-string-replace');

const regex = /(?:^|\B)@([\w]{1,32})(?:$|\b)/gm;
const regex_names = /#([\w\_]*)/gm;

var replace_config = {
    'etiquetas': {
        pattern: regex,
        matcherFn: function (rawText, processed, key) {
            return <span etiqueta="1">#{processed}</span>;
        }
    }
};

export default {
    parse_etiquetas: (raw) => {
        var macth = [];
        while (true) {
            const r = regex.exec(raw);
            if (r === null) break;
            macth.push(r);
        }
        for (let index = 0; index < macth.length; index++) {
            const ma = macth[index];
            raw = raw.replace(ma[0], `<a etiqueta="1" href="/user/${ma[1]}">&commat;${ma[1]}</a>`);
        }

        return raw;
    },
    parse_etiquetas_dom: (raw) => {
        let ii = 0;
        return reactStringReplace(raw, regex, (match, i) => {
            ii++;
            return <Link key={`etiqueta-${ii}`} etiqueta="1" to={`/user/${match}`}>@{match}</Link>;
        });
    },

    parse_etiquetas_shouts_dom: (raw) => {
        let ii = 0;
        return reactStringReplace(raw, regex_names, (match, i) => {
            ii++;
            return <ShoutTag key={`tags-${ii}`} value={{ tag: match }} className="Jy14Y">#{match}</ShoutTag>;
        });
    }
}