import { useEffect, useState } from "react";
import type { UserService } from "../../../node_ts/src/services/user/user.service";
import { ApiType } from "./hooks/apis/useApi";
import React from "react";
export type NodeEnvType = Awaited<ReturnType<UserService["GetNodeEnv"]>>
declare global {
    interface Window {
        nodeEnv: NodeEnvType;
    }
}

export class NodeEnv {
    static getSync() {
        if (NodeEnv.exist()) return window.nodeEnv;
        throw new Error("nodeEnv not fount!")

    }
    static async getAsync() {
        if (NodeEnv.exist()) return window.nodeEnv;
        const data = await window.GET<ApiType<NodeEnvType>>("/api/user/env")
        return data.data as NodeEnvType
    }
    static exist() {
        return "nodeEnv" in window
    }
}

export const LoaderNodeEnv: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [wait, setWait] = useState(!NodeEnv.exist())

    useEffect(() => {
        if (!wait) return;
        NodeEnv.getAsync().then(nodeEnv => {
            window.nodeEnv = nodeEnv
            setWait(false)
        })
    }, [])

    if (wait) {
        return <></>
    }

    return <>{children}</>
} 