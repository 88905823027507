import "react";
import * as React from 'react';
import Switch from '@mui/material/Switch';
import { useEffect, useRef, useState } from "react";
import { refEnv } from "../../../../../contexts/EnvironmentContext";
import { FormControlLabelBlue, FormControlLabelPick, PinkSwitch } from "../../../../mui/Switch";
import { refPeluchan, usePeluchan } from "../../../../../contexts/PeluchanContext";
import { useForceUpdate } from "../../../../utils/useForceUpdate";
import { BuildContextMemo } from "../../../../utils/BuildContextMemo";
import TagManager from "react-gtm-module";

// const filterSchema = z.object({
//   hidden_tags: withFallback(z.array(z.string().max(32)), ["nsfw"]),
//   hidden_users: withFallback(z.array(z.string().length(24)), []),
// })

// export type FilterSchemaType = z.infer<typeof filterSchema>
export type FilterSchemaType = {
    hidden_tags: string[],
    hidden_users: string[]
}

const default_offline: FilterSchemaType = {
    hidden_tags: ["nsfw"],
    hidden_users: []
}

const _get = () => {
    return JSON.parse(localStorage.getItem("shout-filter")) || default_offline
}
const _set = (filter: FilterSchemaType) => {
    localStorage.setItem("shout-filter", JSON.stringify(filter))
}

const default_isAloneFallows = false

const Build = () => {
    const { login: { isLogin } } = usePeluchan()

    const [filter, setFilter] = useState(() => _get())
    const [isAloneFallows, setAloneFallows] = useState<boolean>(() => !!localStorage.getItem("shout-fallows"));
    const refId = useRef(0)
    const { forceUpdate, cound } = useForceUpdate()

    useEffect(() => {
        _set(filter)
    }, [filter])

    useEffect(() => {
        refId.current++;
        forceUpdate()
    }, [isLogin])


    return {
        __deps: [refId.current],
        tagsIncludes: (tag: string) => {
            // if(!isLogin) return default_offline.hidden_tags.includes(tag)
            return filter.hidden_tags.includes(tag)
        },
        get isAloneFallows() {
            if (!isLogin) return false
            return isAloneFallows
        },
        setAloneFallows: (v: Parameters<typeof setAloneFallows>[0]) => {
            refId.current++;
            return setAloneFallows(v)
        },
        changesKey: refId.current,
        get settingApi() {
            if (!isLogin) return {
                filter: JSON.stringify(filter),
                aloneFallows: false
            }

            return {
                filter: JSON.stringify(filter),
                aloneFallows: isAloneFallows
            }
        },
        toggleEtiqueta: (tagId: string) => {
            setFilter(v => {
                const isHidden = v.hidden_tags.includes(tagId)
                refId.current++;

                TagManager.dataLayer({
                    dataLayer: {
                        event: "setter_toggle",
                        tagId: tagId,
                        value: !isHidden,
                        username: refPeluchan()?.login?.user?.username
                    }
                })

                if (isHidden) return { ...v, hidden_tags: [...v.hidden_tags.filter(i => i !== tagId)] }
                else return { ...v, hidden_tags: [...v.hidden_tags, tagId] }

            })

        },
        toggleUser: (userId: string) => {
            setFilter(v => {
                const isHidden = v.hidden_users.includes(userId)
                refId.current++;
                if (isHidden) return { ...v, hidden_users: [...v.hidden_users.filter(i => i !== userId)] }
                else return { ...v, hidden_users: [...v.hidden_users, userId] }
            })
        }
    }
}

const CTX = BuildContextMemo(Build)

export const SetterContentProvider = CTX.Provider
export const useSetterContent = CTX.useContext





export const SetterContent: React.FC<JSX.IntrinsicElements["div"]> = () => {
    const { tagsIncludes, toggleEtiqueta } = useSetterContent()

    const isNsfw = !tagsIncludes("nsfw")
    const isPolitica = !tagsIncludes("politica")

    return <div className="flex items-center justify-center">
        {refEnv().tag.is("|politica") && <FormControlLabelBlue className={"m-0 bg-[#181e2e] pt-[2px] pr-[9px] pb-[2px] pl-[3px] rounded-xl font-sans font-bold  mr-2 " + (isPolitica ? "active text-[#3e71b5]" : "text-[#5d6783]")}
            control={//0px 9px 0px 2px !important
                <Switch checked={isPolitica} className="" onChange={(e) => toggleEtiqueta("politica")} color="info" size="small" />
            }
            label="Politica"
        />}
        <FormControlLabelPick className={"m-0 bg-[#181e2e] pt-[2px] pr-[9px] pb-[2px] pl-[3px] rounded-xl font-sans font-bold  " + (isNsfw ? "active text-[#89335a]" : "text-[#5d6783]")}
            control={//0px 9px 0px 2px !important
                <PinkSwitch checked={isNsfw} className="" onChange={(e) => toggleEtiqueta("nsfw")} size="small" />
            }
            label="NSFW"
        />
    </div>
}