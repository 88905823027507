import React from 'react';
import { PeluchanUpload, UploadResponseType } from '../../utils/services/PeluchanUpload';

type UploadSettingType = {
    uploading: any,

}

class UploadPhoto extends React.Component {
    setting: UploadSettingType
    resolve: (reason?: any) => void = undefined
    error: (reason?: any) => void = undefined
    input: HTMLInputElement = undefined
    async Upload(setting: UploadSettingType): Promise<UploadResponseType> {
        this.setting = setting;
        return new Promise((c, e) => {
            this.resolve = c;
            this.error = e;
            this.input.value = '';
            this.input.click();
        });
    }

    async UploadFile(_this: UploadPhoto, file) {
        const { uploading } = _this.setting;
        if (uploading) uploading()
        PeluchanUpload({ file }).then(_this.resolve).catch(_this.error).finally(() => {
            _this.resolve = undefined
            _this.error = undefined
        })
    }

    render() {
        return (
            <input type="file" ref={(e) => { this.input = e }} style={{ display: "none" }} onChange={(e) => {
                const file = e.target.files[0];
                this.UploadFile(this, file);
            }} />
        );
    }
}

export default UploadPhoto;
