import React, { Fragment } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SR from "../../utils/resources";
import RemoteUpdate from "../../utils/remoteupdate";
import { Profile, Profile_Tooltip } from "../../../pages/user/components/usersesion";
import SendShout from "../../modal/sendshout";
import "./index.scss";

import { refPeluchan, usePeluchan } from "../../../contexts/PeluchanContext";
import { refEnv } from "../../../contexts/EnvironmentContext";
import { Layout } from "../../layout";
import { NanoIconShoutData } from "../../desktop/header/components/ProfileMenu";
import { Imagen } from "../../Imagen/Imagen";
import { SwitchNanoB } from "../../mui/Switch";
import { useSetterContent } from "../../desktop/header/components/SetterContent";

const Item = ({ icon, path, className = "", onClick = undefined, container, regex_active }) => {
    useLocation();

    let dentro = icon;

    if (container) dentro = container(dentro);

    let _active = false;

    if (path) {
        if (regex_active) _active = regex_active.exec(window.location.pathname);
        else if (window.location.pathname === path) _active = true;
    }

    if (path || onClick) {
        return (
            <Link className={`item col ${_active ? "active" : ""} ${className}`} to={path} onClick={onClick}>
                <div className="_content">{dentro}</div>
            </Link>
        );
    }

    let _content = (
        <div className={`item col ${_active ? "active" : ""} ${className}`}>
            <div className="_content">{dentro}</div>
        </div>
    );

    return _content;
};

function Notis({ children }) {
    const {
        login: {
            notis: { count },
        },
    } = usePeluchan();
    return (
        <div>
            {count > 0 ? <span className="num">{count}</span> : null}
            {children}
        </div>
    );
}
function NoLoginMenu(params) {
    useNavigate();
    return (
        <>
            <Item icon={SR._icons._home} path="/home" />
            <Item icon={SR._icons._global} path="/mi" />
            <Item icon={SR._icons._userlogin} path={`/login?returnUrl=${window.location.pathname}`} />
        </>
    );
}
function LoginMenu({ user }) {
    useNavigate();
    return (
        <>
            <Item
                className="create"
                icon={
                    <>
                        {SR._icons._menucreate}
                        <NanoIconShoutData x={-2} y={10} />
                    </>
                }
                onClick={(e) => {
                    e.preventDefault();
                    window.popup.load(<SendShout />);
                }}
            />
            <Item icon={SR._icons._home} path={"/home"} regex_active={/^\/home/} />
            <Item icon={SR._icons._global} path="/mi" regex_active={/^\/mi/} />
            <Item
                icon={SR._icons._notifications}
                path="/notifications"
                onClick={() => refPeluchan().login.notis.toCero()}
                container={(dentro) => {
                    return <Notis>{dentro}</Notis>;
                }}
            />
            <Item
                icon={SR._icons._userlogin}
                container={({ }) => (
                    <Profile_Tooltip user={user} mobile={true} menupopup={<NsfwPanel />}>
                        <Imagen className={"image_user"} pId="PFHlvHM53y" src={user.photo} />
                    </Profile_Tooltip>
                )}
            />
        </>
    );
}


const NsfwPanel = () => {
    const { tagsIncludes, toggleEtiqueta } = useSetterContent()
    return <SwitchNanoB checked={!tagsIncludes("nsfw")} onChange={() => toggleEtiqueta("nsfw")} labelClassName=' !mr-[60px] bg-[#11151f]' label='NSFW' />
}

var firsUpdate = true;
var position = 0;
var positionmenu = 0;
class __MENU_MOBILE__ extends React.Component {
    componentDidMount() {
        const run = () => {
            // this.moveScroll();
        };
        window.addEventListener("scroll", run);
        this.remove = () => window.removeEventListener("scroll", run);

        window.menupopup = (dom, setting) => {
            window.menupopup_open = true;
            window.forceUpdate.run("wajgnwaohgowaghawg", dom, setting);
        };
        window.menupopupclose = () => {
            window.menupopup_open = false;
            window.forceUpdate.run("wajgnwaohgowaghawg", null);
        };
    }
    componentWillUnmount() {
        if (this.remove) this.remove();
        if (window.menupopupclose) window.menupopupclose();
        delete window.menupopup;
        delete window.menupopupclose;
    }

    moveScroll(e) {
        let state = 0;
        const { pageYOffset } = window;
        if (pageYOffset !== position) {
            state = pageYOffset - position;
            position = pageYOffset;
        }
        positionmenu += state;
        if (positionmenu > 48) positionmenu = 48;
        if (positionmenu < -1) positionmenu = -1;
        this.setPosition(positionmenu);
    }

    setPosition(botton = 0) {
        if (botton > 48) botton = 48;
        this.menu.style.bottom = -botton + "px";
    }

    render() {
        return (
            <Fragment>
                <Layout.VerticalSpace exac={"48px"} />
                <div
                    ref={(e) => {
                        this.menu = e;
                    }}
                    className="_mbmenu_"
                >
                    <RemoteUpdate
                        _key="wajgnwaohgowaghawg"
                        content={(dom, { className = "", title = "", close = true, dom_offset, Delete = () => window.menupopupclose } = {}) =>
                            !dom ? null : (
                                <div className={`popmenu  ${className}`}>
                                    {!title && !close ? null : (<>
                                        <div className="popmenu-header toolbal flex justify-between">
                                            <h5 className="truncate">{title}</h5>
                                            <div className="flex items-center">
                                                {dom_offset}
                                                <button
                                                    onClick={() => {
                                                        window.menupopupclose();
                                                    }}
                                                    title="Ocultar menu"
                                                    type="button"
                                                    tabIndex="-1"
                                                    className="tox-tbtn"
                                                    aria-pressed="false"
                                                >
                                                    {SR._icons._close}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    )}
                                    <div className="popmenu-content">{dom}</div>
                                </div>
                            )
                        }
                    />
                    <div className="row">
                        <Profile
                            loading={() => <NoLoginMenu />}
                            err={() => <NoLoginMenu />}
                            ok={(r, s, p) => {
                                return <LoginMenu user={r?.user} />;
                            }}
                            out={() => <NoLoginMenu />}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export const MobileContent = ({ children }) => {
    return <div className="jXnVrnrLuK">{children}</div>;
};
export const SimulateCenter = ({ children }) => {
    return <div className="TdyTB27fa3">{children}</div>;
};

export default __MENU_MOBILE__;
