import React from "react";
import cn from "classnames";
import { Timer } from "../../../../../../components/styleguide/components/Timer";

const NIVEL_COLORS = ["#FFF", "#AEE5FF", "#BEFFB5", "#8ACCFF", "#FFB4B4", "#FFC387"]
export const MedallaInfoWithNivel: React.FC<React.SVGProps<SVGSVGElement> & {
    title: string,
    description: string,
    nextDescription: string,
    niveles: {
        list: number[]
        value: number
    },
    nivel: number,
    porcen: number,
    created: string | undefined
}> = ({
    title,
    description,
    nextDescription,
    nivel,
    created
}) => {

        return (
            <div className={cn("pa3", { "o-50": nivel === 0 })}>
                <h4 style={{ color: NIVEL_COLORS[nivel] }}>
                    {title}
                </h4>
                {description ? (
                    <div className="f6 mt2">
                        {description}
                    </div>
                ) : null}
                {nivel === 0 ? null : (
                    <div className="flex mt3">
                        <h6 className="mr5" style={{ color: NIVEL_COLORS[nivel] }}>
                            Nivel {nivel}°
                        </h6>
                        <div className="flex-grow-1" />
                        <h6 className="gray">
                            Desde {created ? <Timer formet="DD MMM [de] YYYY" time={created} /> : ""}
                        </h6>
                    </div>
                )}
                {nextDescription ? <>
                    <div className="bb mt2 mb4 gray" />
                    <div className="f8 " style={{ color: NIVEL_COLORS[nivel + 1] }}>
                        * {nextDescription}
                    </div>
                </>
                    : null}
            </div>
        );
    }