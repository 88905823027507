import React from 'react';

import EditorList from "../EditorList/editorlist";

const SendBug = () => {
    const _this = this;
    var message = "";
    //var body = null;

    const dlist = [{
        key: "bajo",
        text: "Bajo"
    }, {
        key: "moderado",
        text: "Moderado"
    }, {
        key: "alto",
        text: "Alto"
    }, {
        key: "critico",
        text: "Crítico"
    }];
    var nivel = "moderado";

    return (
        <div className="modal fade show" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title flex items-center">
                            <span className="material-icons mr-2">feedback</span>
                            {`Reportar bug`}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                            window.popup.close();
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <span className='text-gray-300 block mb-1'>Descripcion</span>
                            <EditorList editor="text" placeholder={"Descripción del problema"} onChange={(body) => {
                                message = body.content;
                            }}></EditorList>
                        </div>
                        <div className='mt-3'>
                            <span className='text-gray-300'>Gravedad del problema</span>
                            <select className="custom-select d-block w-100 mt-1" id="category" required onChange={(e) => {
                                if (!e) return;
                                nivel = e.target.value;
                            }}>
                                {dlist.map((e) => {
                                    return (
                                        <option key={e.key} value={e.key}>{`${e.text}`} </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                            window.popup.close();
                        }}>Cancelar</button>
                        <button type="button" className="btn btn-primary" onClick={async () => {
                            const l = await window.RPOST("/api/report_bug", {
                                parent: {
                                    nivel,
                                    text: message ? message : undefined
                                }
                            });
                            // if(!_this.editorlist) return;
                            // _this.editorlist.clear();
                            if (l) window.popup.close();
                        }} >Enviar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SendBug;