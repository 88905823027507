import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'


declare global {
    interface Window {
        MScreen: typeof MScreen
    }
    type awaited<T> = T extends PromiseLike<infer U> ? U : T
}

class MScreen {
    isDesktop?: boolean;
    isTablet?: boolean;
    isMobile?: boolean;
    isNotMobile?: boolean;
    constructor() {
        this.isDesktop = useMediaQuery({ minWidth: 992 });
        this.isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
        this.isMobile = useMediaQuery({ maxWidth: 767 });
        this.isNotMobile = useMediaQuery({ minWidth: 768 });
    }
}

export const useScreen = () => {
    return {
        isDesktop: () => useMediaQuery({ minWidth: 992 }),
        isTablet: () => useMediaQuery({ minWidth: 768, maxWidth: 991 }),
        isMobile: () => useMediaQuery({ maxWidth: 767 }),
        isNotMobile: () => useMediaQuery({ minWidth: 768 }),
    }
}

export const useDevice = () => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const isNotMobile = useMediaQuery({ minWidth: 768 })

    return { isDesktop, isTablet, isMobile, isNotMobile }
}

window.MScreen = MScreen;