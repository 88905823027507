import * as React from "react"
import { SVGProps, memo } from "react"

const SvgComponent = ({ borde1, background1, backgroundShadow1, children, ...props }: SVGProps<SVGSVGElement> & {
    borde1: string,
    background1: string,
    backgroundShadow1: string
}) => {
    return (
        <>
            <g data-name="Grupo 7" transform="rotate(-20 -975.796 927.549)">
                {/* <circle cx="250" cy="35" r="25" style={{
                stroke: "#009900",
                fill:"#00ff00"
            }}/> */}
                <defs>
                    <clipPath id="clip" clipPathUnits="objectBoundingBox">
                        <circle
                            cx={12.5}
                            cy={12.5}
                            r={12.5}
                            transform="translate(257 401)"
                        />
                    </clipPath>
                </defs>

                <circle
                    data-name="Elipse 1"
                    cx={14.5}
                    cy={14.5}
                    r={14.4}
                    transform="translate(255 399)"
                    fill={borde1}
                />
                <circle
                    data-name="Elipse 2"
                    cx={12.5}
                    cy={12.5}
                    r={12.5}
                    transform="translate(257 401)"
                    fill={background1}
                />

                <path
                    data-name="Sustracci\xF3n 1"
                    d="M259.293 420.628a12.433 12.433 0 0 1 17.315-17.3 12.813 12.813 0 0 0-17.314 17.3Z"
                    fill={backgroundShadow1}
                />
            </g>
            <defs>
                <clipPath id="object-bounding" clipPathUnits="objectBoundingBox">
                    <circle cx="0.45" cy="0.50" r="0.2" />
                </clipPath>
            </defs>
            {children}
        </>
    );
}

const Memo = memo(SvgComponent)
export default Memo
