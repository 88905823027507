
import LoadingButton, { LoadingButtonProps, loadingButtonClasses } from '@mui/lab/LoadingButton';
import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';

export const LoadingButtonInShouts = styled(({ className, ...props }: LoadingButtonProps & { onClick: () => void }) => (
    <LoadingButton size="small" {...props} classes={{ root: className }} />
))(({ theme }) => ({
    [`& ${loadingButtonClasses.loading}`]: {
        color: "#FFFFFF"
    },
    [`&.Mui-disabled`]: {
        color: "#94ceff !important",
        background: "#151a27 !important"
    },
})); 