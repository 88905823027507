import React from "react";
import { useCssHandles } from "../../../../hooks/useCssHandles";
import { MedallaType, Niveles } from "../types";
import cn from "classnames";
import { PlantillaIcon } from "./icono/plantilla";
import { MedallaTooltip, MedallaMobileTooltip } from "../../../../components/mui/Tooltip";
import { PlantillaInfo } from "./info/plantilla";
import { useScreen } from "../../../../components/utils/mscreen";

export const Medalla: React.FC<{
    value: MedallaType,
    className?: string
}> = ({ value, className }) => {
    const isMobile = useScreen().isMobile()
    const styles = useCssHandles("tags", [
        "item"
    ] as const);

    const { active, icon, info } = value;
    // icon.border1 = "#FFF"
    // icon.borde1 = "#FFF"
    // if(icon.icon == "acolito") icon.color = "#287e59"
    // console.log(icon)

    if (isMobile) return (
        <div className={cn("pointer", styles.item, className)} onClick={() => {

        }}>
            <MedallaMobileTooltip disableInteractive enterDelay={0} leaveDelay={0} placement="top" arrow={false} border1="#467286" title={
                <PlantillaInfo active={active} {...info} />
            }>
                <span>
                    <PlantillaIcon active={active} {...icon} className="" />
                </span>
            </MedallaMobileTooltip>
        </div>
    );

    return <div className={cn("pointer", styles.item, className)} onClick={() => {

    }}>
        <MedallaTooltip disableInteractive enterDelay={0} leaveDelay={0} placement="left" border1="#467286" title={
            <PlantillaInfo active={active} {...info} />
        }>
            <span>
                <PlantillaIcon active={active} {...icon} className="" />
            </span>
        </MedallaTooltip>
    </div>
}
