import { useState } from "react"
import { IShout } from "../../../../../../hooks/apis/usePost"
import { MemoHeightProvider } from "../../../../../../hooks/useMemoHeight"
import ItemShout from "../../../item-shout"
import { EditorShoutItemType } from "../editor-shout"
import React from "react"
import { Button } from "../../../../../../components/styleguide/components/Button"


export const DynLink: React.FC<{
    onDelete: () => void,
    setContent: (v: { url?: string }) => void
    item: EditorShoutItemType<{ url?: string }>,
    defauld_setting?: {
        native: {
            type: string,
            content: unknown
        }
    }
}> = ({ onDelete, setContent, item, defauld_setting }) => {
    const { content = {} } = item;
    const [value, setValue] = useState<string | undefined>();

    let CONTENT = null;

    if (!content.url) {
        CONTENT = (
            <div className="input-group mb-3">
                <input value={value} onChange={e => setValue(e.target?.value)} type="text" className="form-control" placeholder="Enlace" aria-label="Enlace" aria-describedby="button-addon2" autoFocus />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => setContent({ url: value })}>Insertar</button>
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={onDelete}>Volver</button>
                </div>
            </div>
        );
    } else {
        let content_body = <>
            <div className='link max-w-[250px] line-clamp-1' title={content.url}><a href={content.url}>{content.url}</a></div>
            <Button.Icon
                className="absolute top-0 right-0 !w-[24px] !h-[24px] text-[16px] translate-y-[-3px]"
                onClick={onDelete}
                type="flatBlue"
                title="Quitar"
                icon="close"
            />
        </>;

        if (defauld_setting && defauld_setting.native) {
            if (defauld_setting.native.type === "shout" && defauld_setting.native.content) {
                content_body = (<>
                    <MemoHeightProvider name="shared-editor">
                        <ItemShout
                            shout={defauld_setting.native.content as IShout}
                            // full={true} hidden_footer={true} no_setting={true}
                            className="media-shared-shout p-0.5"
                            style="none"
                        />
                    </MemoHeightProvider>
                    <Button.Icon
                        className="absolute top-0 right-0 !w-[24px] !h-[24px] text-[16px]"
                        onClick={onDelete}
                        type="flatBlue"
                        title="Quitar"
                        icon="close"
                    />
                </>)
            }
        }
        CONTENT = (
            <div className="toolbal SelectOption">
                <div className="button_close item_link ">
                    {content_body}
                </div>
            </div>
        );
    }

    return <div className="dyn-link">
        {CONTENT}
    </div>;
}
