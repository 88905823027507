import { FunctionComponent, PropsWithChildren, useMemo } from 'react'
import React, { createContext, useContext } from 'react'
import { Memo } from './Memo'


export function BuildContextMemo<T extends (args: Record<any, any>) => any>(BUILD: T) {
    const CTX = createContext<ReturnType<T>>({} as any)
    const Provider: React.FC<PropsWithChildren<Parameters<T>[0]>> = ({
        children,
        ...args
    }) => {
        const value = BUILD(args)
        if (value.__deps) {
            return <Memo debs={value.__deps}>
                {() => <CTX.Provider value={value}>{children}</CTX.Provider>}
            </Memo>
        }
        return <CTX.Provider value={value}>{children}</CTX.Provider>
    }

    function buildWithContext<Props>(
        C: FunctionComponent<Props>,
        ...args: Parameters<T>
    ) {
        return function withContext(props: Props) {
            return (
                <Provider args={args}>
                    <C {...props} />
                </Provider>
            )
        }
    }

    return {
        Provider,
        useContext: () => useContext(CTX),
        withContext: buildWithContext,
    }
}

export function BuildProps<T>() {
    const CTX = createContext<T>({} as any)
    const { Provider } = CTX
    const useProps = () => useContext(CTX)

    return {
        Provider,
        useProps,
    }
}
