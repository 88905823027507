import { useEffect, useRef } from "react";
import { LazyState } from "../../../../components/utils/LazyState";
import { SignalUpdate, useSignalUpdate } from "../../../../components/utils/useSignalUpdate";

const find = (commentId: string) => {
    return document.getElementById(commentId);
}

export const CommentFocus: React.FC = () => {
    const refClear = useRef<() => void>()
    useEffect(() => {
        if (window.location.hash == "") return
        const commentId = window.location.hash.replace("#", "")
        const isRepeat = LazyState.get(window.location.hash)
        if (!isRepeat) {
            // if(!find(commentId)) return window.log("Comentario no encontrado")
            if (!find(commentId)) return;
            if (refClear.current) refClear.current()
            LazyState.set(window.location.hash, 1)
            setTimeout(() => {
                find(commentId)?.scrollIntoView();
                find(commentId)?.classList.add("_push_");
                refClear.current = () => {
                    find(commentId)?.classList.remove("_push_");
                    refClear.current = undefined
                }
                setTimeout(refClear.current, 2000);
            }, 300);
        }
    })
    return null
}
/**
 * Fuerza una actualizacion del focus de los comentarios.
 *
 * @export
 * @param {string} id Id del comentario
 * @param {string} url Url de posts/shout
 */
export function RetryCommentFocus(id: string, url: string) {
    LazyState.set(`#${id}`, undefined)
    SignalUpdate(url)
}