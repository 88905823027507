import React from "react";
import { PollCreator, initPoll } from "../../../../../../components/polls/PollCreator";
import { PlantillaType } from "../../../../../../contexts/EnvironmentContext/usePlantilla";
import { useRefer } from "../../../../../../hooks/useRefer";
import { EditorShoutItemType } from "../editor-shout";

export type PollParseType = {
    pollId?: string,
    poll?: Parameters<PlantillaType["poll"]["create"]>[0]
}

export const DynPoll: React.FC<{
    onDelete: () => void,
    setContent: (v: PollParseType) => void,
    item: EditorShoutItemType<PollParseType>
}> = ({ onDelete, item, setContent }) => {
    const refer = useRefer({ setContent });

    return (
        <div className="dyn-media">
            <div className="toolbal SelectOption button_close">
                <PollCreator onClose={onDelete} pollDefault={item.content.poll || initPoll} onChange={p => refer.current.setContent({ poll: p })} />
                {/* <button onClick={onDelete} aria-label="Quitar" title="Quitar" type="button" className="tox-tbtn" aria-pressed="false"><span className="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M17.953 7.453L13.422 12l4.531 4.547-1.406 1.406L12 13.422l-4.547 4.531-1.406-1.406L10.578 12 6.047 7.453l1.406-1.406L12 10.578l4.547-4.531z" fillRule="evenodd"></path></svg></span><span className="close"></span></button> */}
            </div>
        </div>
    );
}