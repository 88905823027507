import React, { useMemo } from "react";
import { useEnv } from ".";

export const useTags = (raw = "") => {
    const tags = useMemo(() => {
        const custom_tags = localStorage.getItem("custom_tags")
        if (custom_tags) return custom_tags.split(" ")
        const extras_tags = localStorage.getItem("extras_tags")
        if (extras_tags) return `${raw} ${extras_tags}`.split(" ")
        return raw.split(" ")
    }, [raw]);
    return {
        contexts: {
            tag: {
                is: (key: string) => tags.includes(key),
            },
        },
    };
};

export type TagIsProps = {
    id: string;
};
export const TagIs: React.FC<React.PropsWithChildren<TagIsProps>> = ({ children, id }) => {
    const {
        tag: { is },
    } = useEnv();

    if (is(id)) return <>{children}</>;

    return null;
};
