import React from "react";

export const RepeatNode = (count: number, children: React.ReactNode | ((index: number, last: boolean) => React.ReactNode)) => {
    return Array.from({ length: count }).map((_, index) => (
        <React.Fragment key={index}>{typeof children == "function" ? children(index, index + 1 == count) : children}</React.Fragment>
    ));
};

export const RepeatRangeNode = (count: number, children: React.ReactNode) => {
    return Array.from({ length: count }).map((_, index) => (
        <React.Fragment key={index}>{children}</React.Fragment>
    ));
};


export const RepeatChildren: React.FC<React.PropsWithChildren & { count: number }> = ({ count, children }) => {
    return (
        <>
            {RepeatNode(count, children)}
        </>
    );
};
