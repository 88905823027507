import React from "react"
import { UserType } from "../../../../hooks/apis/useUser"
import { Timer } from "../../../../components/styleguide/components/Timer"
import { Link } from "react-router-dom"
import cn from "classnames"

export const CommentHeader: React.FC<{ user: UserType, created?: Date, isMe?: boolean }> = ({ user, created, isMe }) => {
    return <div className="header h-[16px] leading-[1] pt-0 line-clamp-1">
        <span className='truncate'>
            <Link className={cn('font-bold text-[16px]', !isMe ? "text-[#00a2ff]" : "text-[#ff0d72]")} to={`/user/${user._id}/${user.username}`}>{user.name}</Link>
            <span className="inline-block dark:bg-gray-500/75 bg-gray-400 rounded-full h-1 w-1 align-middle mx-1.5" />
            <span className="username text-[14px] color_rosa">{user.username}</span>
        </span>
        {created && <>
            <span className="inline-block dark:bg-gray-500/75 bg-gray-400 rounded-full h-1 w-1 align-middle mx-1.5 mr-1 " />
            {/* <span className="material-icons text-gray-400 align-middle text-[12px] translate-y-[-1px] mr-0.5">schedule</span> */}
            <Timer.withHover time={created} className=" text-xs text-gray-400" />
        </>}
    </div>
}