import * as React from "react";
import { SVGProps, memo } from "react";

const Icon = ({
    color,
    ...props
}: SVGProps<SVGGElement> & {
    color: string;
}) => (
    <>
        <g {...props} transform={`translate(2.6 -1), scale(.17 .17), rotate(15)`}>
            <path fill={color} d="M47.207,1.039c1.598,0,3.197,0,4.795,0c0.101,0.023,0.201,0.057,0.304,0.066
          c2.011,0.185,4.031,0.302,6.031,0.565c3.786,0.497,7.427,1.518,10.808,3.336c2.471,1.329,4.633,3.025,6.119,5.462
          c0.2,0.328,0.411,0.453,0.799,0.451c3.392-0.016,6.784-0.012,10.176-0.008c2.557,0.003,5.115-0.028,7.67,0.032
          c2.153,0.051,3.612,1.634,3.615,3.787c0.006,4.279-0.503,8.484-1.727,12.597c-1.44,4.843-3.796,9.192-6.986,13.094
          c-3.169,3.875-6.924,7.12-11.001,9.989c-3.48,2.448-7.033,4.791-10.536,7.207c-2.925,2.017-5.781,4.122-8.088,6.87
          c-2.08,2.478-3.232,5.308-3.24,8.568c-0.006,2.184-0.002,4.369,0,6.553c0,0.186,0.017,0.372,0.025,0.536
          c0.387,0.058,0.702,0.112,1.018,0.15c3.537,0.422,6.973,1.236,10.242,2.677c1.697,0.748,3.294,1.664,4.582,3.032
          c2.05,2.178,2.009,4.708-0.09,6.848c-1.695,1.728-3.812,2.759-6.035,3.581c-3.699,1.368-7.546,2.052-11.47,2.333
          c-0.775,0.055-1.548,0.131-2.321,0.198c-1.492,0-2.983,0-4.475,0c-0.172-0.029-0.343-0.068-0.516-0.085
          c-1.869-0.182-3.748-0.295-5.606-0.553c-3.612-0.5-7.116-1.412-10.379-3.097c-1.557-0.804-2.989-1.775-4.033-3.227
          c-1.227-1.708-1.174-3.755,0.126-5.419c0.959-1.228,2.192-2.115,3.539-2.851c2.923-1.597,6.089-2.475,9.34-3.079
          c1.145-0.213,2.303-0.358,3.48-0.538c0-2.454,0.025-4.886-0.007-7.317c-0.03-2.203-0.609-4.271-1.724-6.177
          c-1.544-2.638-3.724-4.703-6.148-6.483c-3.042-2.235-6.172-4.352-9.298-6.47c-3.777-2.559-7.516-5.16-10.9-8.237
          c-3.666-3.332-6.795-7.093-9.099-11.498C3.019,27.859,1.73,21.348,1.811,14.54c0.007-0.56,0.175-1.167,0.435-1.666
          c0.748-1.441,2.034-1.972,3.613-1.969c5.824,0.011,11.648,0.007,17.471-0.007c0.204-0.001,0.502-0.114,0.596-0.272
          c1.47-2.453,3.608-4.173,6.068-5.519c4.503-2.465,9.417-3.423,14.466-3.854C45.376,1.175,46.292,1.11,47.207,1.039z
          M49.685,23.866c2.614,0.024,5.206-0.194,7.777-0.665c2.984-0.548,5.853-1.425,8.47-3.001c1.269-0.764,2.429-1.667,3.299-2.893
          c0.81-1.142,0.849-2.059,0.002-3.173c-0.5-0.657-1.08-1.281-1.724-1.796c-2.814-2.249-6.128-3.361-9.606-4.029
          c-3.552-0.682-7.141-0.87-10.754-0.671c-3.412,0.188-6.768,0.658-9.998,1.824c-2.441,0.882-4.73,2.025-6.522,3.97
          c-1.532,1.662-1.524,2.865-0.001,4.547c1.286,1.421,2.879,2.407,4.601,3.192C39.822,23.266,44.707,23.826,49.685,23.866z
          M70.827,46.052c0.035,0.029,0.069,0.057,0.104,0.086c0.893-0.636,1.79-1.267,2.68-1.908c3.465-2.497,6.698-5.249,9.408-8.576
          c3.603-4.424,5.738-9.487,6.58-15.112c0.106-0.71,0.17-1.427,0.257-2.167c-4.493,0-8.903,0-13.353,0
          C76.184,27.941,74.858,37.274,70.827,46.052z M28.393,46.133c-3.938-8.855-5.264-18.201-5.585-27.756c-4.476,0-8.885,0-13.319,0
          c0,0.16-0.014,0.285,0.002,0.405c0.097,0.719,0.196,1.438,0.305,2.155c0.945,6.206,3.581,11.601,7.8,16.234
          c2.436,2.675,5.205,4.969,8.134,7.077C26.613,44.883,27.505,45.505,28.393,46.133z"/>
        </g>
    </>
);

export default ({
    borde1,
    primary1,
    backgroundShadow1,
}: SVGProps<SVGGElement> & {
    borde1: string;
    primary1?: string;
    backgroundShadow1: string;
}) => (
    <>
        <g transform="translate(-0.5 4.7),  rotate(-15)">
            <g transform="translate(1.4 1.5)">
                <Icon color={backgroundShadow1} />
            </g>
            <Icon color={primary1 || borde1} />
        </g>
    </>
);
