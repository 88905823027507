import React from 'react';
import { Link } from 'react-router-dom'
import cn from 'classnames';

import { Imagen } from '../../../../components/Imagen/Imagen';
import { IPost } from '../../../../hooks/apis/usePost';
import { PostMetas } from './metas';
import { ImagenRatio } from '../../../../components/Imagen/ImagenRatio';

export const ItemPostReco: React.FC<{
    post: IPost,
    insertado?: boolean
}> = ({ post, insertado }) => {
    let author = post.author
    if (!author) author = window.user404;

    const portada = post.portada ? post.portada : author.photo

    return (
        <Link title={post.title} to={`/post/${post._id}/${post.slug}`}>
            <div className={cn("overflow-hidden relative", { "rounded-md border-[#0d111c] border-[2px] border-solid": !insertado })} >
                {portada && <ImagenRatio ratio={67 / 90} className={cn("mr-3 w-full")} pId={"m5vSGtEFbX"} pParams={{ options: { s2: { varians: ["profile.default", "post.default"] } } }} src={portada} style={{ backgroundPosition: "center", backgroundSize: "cover" }} />}
                <div className={cn("media-body  flex-none w-full pt-3 bottom-0 left-0 right-0 p-2 bg-gradient-to-t dark:from-[#171d2d] dark:to-[#171d2dc2]", { "absolute": portada })}>
                    <h5 className='title font-bold text-[#57a8ff] text-[18px] leading-5 line-clamp-2'>{post.title}</h5>
                    <PostMetas post={post} showViews={true} />
                </div>
            </div>
        </Link>
    )
}
