import { useState, useEffect, useRef } from "react";
import useSWR from 'swr/immutable'
import { serialize } from "../../api";
import { ApiType } from "./useApi";
import { ReturnSWR } from "./useShout";
import type { IBody } from "../../components/utils/ParseContent/Components/PMain";
import { ScrollByNextIdServer } from "../../pages/components/Scrolling/utils/ByNextId";
import { useSWP } from "./useSWP";

export type IPost = {
    author: {
        name: string
        photo: string
        username: string
        _id: string
    }
    created: number
    last_comment_time: string
    meta_comments: number
    meta_puntos: number
    meta_vistas: number
    meta_favs: number
    lastedit: number
    mtags: string[]
    comments: any[]
    comments_destacate: any[]
    portada: string
    etiquetas: string[]
    sources: string[]
    slug: string
    fav: boolean
    title: string
    _id: string
    category: {
        slug: string
        name: string
    }
    body: IBody<"string/html", string>
}

type PostType = {
    isEnd: boolean
    list: IPost[]
    nextId: string
    stickys: {
        to: IPost;
        expireAt: Date
        startAt: Date
        createdAt: Date
    }[]
}

export enum SoftPost {
    Recientes = "date_news",
    Antiguos = "date_olds",
    Relevantes = "relevance",
    RelevantesV3 = "relevance_v3",
    Score = "score",
    Match = "match",
    UltimoComentario = "last_comment",
    done = "none",
}

export type IShout = {
    _id: string
    portada?: {
        url: string
        width: number
        height: number
        format: string
    }
    body: {
        content: any
        type: string
    }
    text: string
    etiquetas: Array<string>
    meta_favs: number
    meta_vistas: number
    comments: Array<string>
    meta_comments: number
    positivos: number
    negativos: number
    shareds: number
    up: boolean
    down: boolean
    created: string
    last_comment_time: string
    author: {
        _id: string
        photo: string
        username: string
        name: string
    }
    __v: number
    shared: boolean
    fav: boolean
    hidden_content?: boolean
}

export type SearchShoutResponse = ScrollByNextIdServer<IShout>
export const FindPosts = ({
    q,
    random,
    interval,
    category,
    group,
    soft = SoftPost.Recientes,
    user_id,
    count = 30,
    nextId,
    see_relevance,
    omit_stickys,
    skip,
    lastid,
    uff
}: {
    q?: string,
    random?: boolean,
    interval?: string,
    category?: string,
    group?: string,
    soft?: SoftPost,
    user_id?: string,
    count?: number,
    nextId?: string,
    lastid?: string,
    see_relevance?: boolean,
    omit_stickys?: boolean
    skip?: number,
    uff?: boolean
} = {}) => {
    return window.GET2<ApiType<PostType>>(`/api/post/search`, {
        q,
        random,
        interval,
        category,
        group,
        soft,
        user_id,
        count,
        see_relevance,
        omit_stickys,
        lastid,
        v: 1,
        uff,
        ...nextId ? { nextId: nextId } : {},
        ...skip ? { skip: skip } : {}
    });
}

export const usePosts = (params: Parameters<typeof FindPosts>[0], stg: {
    /**
     * Para prevenir que se pierda el scroll en la navegacion
     */
    _default?: awaited<ReturnType<typeof FindPosts>>["data"]["list"],
    _useSkip?: boolean
} = {}) => {
    const [posts, setPosts] = useState<awaited<ReturnType<typeof FindPosts>>["data"]["list"]>(stg?._default || []);
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState<boolean>(stg?._default ? false : true);

    const ref = useRef<typeof _ref>();

    const _ref = {
        posts,
        setPosts,
        error,
        setError
    }

    ref.current = _ref;

    const loadMore = ({ clear }: { clear?: boolean } = {}) => {
        return new Promise<{ posts: typeof posts, hasMore: boolean }>((c, e) => {
            if (error) setError(undefined);
            if (clear) setLoading(true);
            FindPosts({
                ...params,
                ...!clear ? {
                    ...stg._useSkip ? {
                        skip: _ref.posts.length ? _ref.posts.length - 1 : undefined
                    } : {
                        lastPostId: _ref.posts[_ref.posts.length - 1]?._id || undefined
                    }
                } : {}
            }).then((result) => {
                const _posts = [
                    ...clear ? [] : _ref.posts,
                    ...(result.data?.list || []).filter(p => _ref.posts.findIndex(p2 => p2._id === p._id) === -1)
                ];
                if (_posts) _ref.setPosts(_posts || []);
                setLoading(false);
                c({ posts: _posts, hasMore: result.data?.list?.length > 0 });
            }).catch((err) => {
                setError(err);
                setLoading(false);
                e(err);
            });
        });
    };

    useEffect(() => {
        if (stg?._default) return;
        loadMore({ clear: true });
    }, [JSON.stringify(params)]);

    const result = {
        loadMore,
        posts
    }

    return [result, loading, error] as [typeof result, boolean, typeof error]
};

type PostParams = {
    author: number;
    rate: number;
    _id: any;
    relacionados: number;
    see: number;
    comments: number;
}
export const usePost = (args: Partial<PostParams>, deps: any[] = []) => {
    return useSWP<{
        post: IPost,
        rate: number,
        relacionados: any[]
    }>([`/api/post/get?${serialize(args)}`, ...deps])
}