import { useState } from "react";
import { BuildContext } from "../../components/utils/build_context";

const CTX = BuildContext(() => {
    const isSSR = typeof window == "undefined"
    const [theme, setTheme] = useState<"dark" | "light" | "default">(() => isSSR ? "" : localStorage.theme)

    const loadTheme = () => {

        //if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        if (localStorage.theme === 'dark' || !('theme' in localStorage)) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    const changeTheme = (theme: "light" | "dark" | "default") => {
        if (theme == "default") {
            localStorage.removeItem('theme')
            setTheme(undefined)
        }
        else {
            localStorage.theme = theme
            setTheme(theme)
        }

        loadTheme()
    }
    const toggleTheme = () => {
        if (theme == "dark") return changeTheme("light")
        if (theme == "light") return changeTheme("dark")
        changeTheme("light")
    }

    return {
        theme,
        setTheme: changeTheme,
        toggleTheme
    }
});

export const useTheme = CTX.useContext;
export const ProviderTheme = CTX.Provider;
export const withTheme = CTX.withContext;
