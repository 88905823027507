import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useMedia } from "./useMedia";
import { usePlantilla } from "./usePlantilla";
import { useSnack } from "./useSnack";
import { useTags } from "./useTags";
import { NodeEnv, NodeEnvType } from "../../env";


declare global {
    interface Window {
        environment: any;
    }
}

const Environment = createContext<ReturnType<typeof _ENV>>({} as any);

function _ENV() {
    const [environment, setEnvironment] = useState(NodeEnv.getSync());

    const setEnv = (raw: NodeEnvType) => {
        if (JSON.stringify(environment) === JSON.stringify(raw)) return;
        setEnvironment(raw);
    };

    const tags = useTags(environment?.tags);
    const media = useMedia(environment?.scripts?.media, tags.contexts.tag);
    const plantilla = usePlantilla(environment?.plantilla as any);
    const snack = useSnack();

    useEffect(() => {
        console.log("Environment:", environment)
    }, [])

    return {
        env: environment,
        setEnvironment: setEnv,
        ...tags.contexts,
        ...media.contexts,
        ...plantilla.contexts,
        ...snack.contexts,
    };
}

export const EnvironmentContext: React.FC<React.PropsWithChildren> = ({ children }) => {
    const _private = _ENV();

    window.environment = _private;

    return <Environment.Provider value={_private}>{children}</Environment.Provider>;
};

export const useEnv = () => {
    return useContext(Environment);
};

export const refEnv = () => {
    return window.environment as ReturnType<typeof useEnv>;
};
