import React, { useEffect, useReducer } from "react"
import { PlantillaType } from "../../contexts/EnvironmentContext/usePlantilla"
import cloneDeep from "lodash/cloneDeep";
import "./PollCreator.styles.scss";
import cn from "classnames";
import { useCssHandles } from "../../hooks/useCssHandles";
import { refEnv } from "../../contexts/EnvironmentContext";
import SR from '../utils/resources';
import { TimeLeftSelector } from "./TimeLeftSelector";
import { AxiosError } from "axios";
import { PollParseType } from "../../pages/components/Shout/components/Editor/dyns/DynPoll";


type State = {
    poll: Parameters<PlantillaType["poll"]["create"]>[0],
    isMaxAnswers?: boolean
}

type Action =
    | { type: 'add_answer'; }
    | { type: 'remove_answer'; indexAnswer: number }
    | { type: 'update_question'; question: State["poll"]["question"] }
    | { type: 'update_answer'; indexAnswer: number, answer: State["poll"]["answers"][0] }
    | { type: 'update_duration'; duration: number }

export const initPoll: State["poll"] = {
    answers: [{ type: "text", content: "" }, { type: "text", content: "" }],
    question: undefined,
    duration: 60 * 24
}

const Reducer = (state: State, action: Action): State => {
    console.log("🔹 ACTION: ", state, action)
    switch (action.type) {
        case 'add_answer':
            state = cloneDeep(state);
            state.poll.answers.push({ type: "text", content: "" })
            state.isMaxAnswers = state.poll.answers.length >= 5;
            return state

        case 'remove_answer':
            state = cloneDeep(state);
            state.poll.answers.splice(action.indexAnswer, 1)
            state.isMaxAnswers = state.poll.answers.length >= 5;
            return state

        case 'update_question':
            state = cloneDeep(state);
            state.poll.question = action.question
            return state

        case 'update_answer':
            state = cloneDeep(state);
            state.poll.answers[action.indexAnswer] = action.answer;
            return state

        case 'update_duration':
            state = cloneDeep(state);
            state.poll.duration = action.duration;
            return state

        default:
            throw new Error()
    }
}


export const PollCreator: React.FC<{
    pollDefault: State["poll"],
    onChange: (poll: State["poll"]) => void,
    onClose: () => void
}> = ({ pollDefault, onChange, onClose = () => { } }) => {
    const styled = useCssHandles("DfNcb", ["input", "button", "button_close", "button_add", "button_back"] as const);
    const [{ poll, isMaxAnswers }, dispath] = useReducer(Reducer, { poll: pollDefault });

    const isShowClose = poll.answers.length > 2;

    useEffect(() => {
        onChange(poll)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(poll)])

    return (
        <div className="pa3 w-100">
            <div className="mb3">
                <input className={cn("w-100", styled.input)} placeholder={"Titulo (Opcional)"} value={poll.question} onChange={e => dispath({ type: "update_question", question: e.target.value })} />
            </div>
            {poll.answers.map((anw, indexAnswer) => {
                const lastItem = indexAnswer + 1 === poll.answers.length && !isMaxAnswers;
                return (<div key={indexAnswer} className="mb2 flex w-100">
                    <input
                        value={anw.content}
                        placeholder={`Opcion ${indexAnswer + 1}`}
                        className={cn("flex-grow-1", { noborde: isShowClose }, styled.input)}
                        onChange={e => dispath({ type: "update_answer", indexAnswer, answer: { type: "text", content: e.target.value } })}
                    />
                    {isShowClose && <button className={cn(styled.button, styled.button_close)} onClick={e => dispath({ type: "remove_answer", indexAnswer })}>{SR._icons._close3({ size: 14 })}</button>}
                    {lastItem && <button className={cn(styled.button, styled.button_add, "mr2")} onClick={e => dispath({ type: "add_answer" })}>{SR._icons._plus({ size: 20 })}</button>}
                </div>)
            })}
            <div className="mt3 flex flex-column justify-between">
                <span className="gray">Duración de la encuesta</span>
                <TimeLeftSelector min={5} max={7 * 60 * 24} value={poll.duration} onChange={(duration) => dispath({ type: "update_duration", duration })} className="mb3" />
                <button className={cn(styled.button, styled.button_back)} onClick={e => onClose()}>Volver</button>
            </div>
        </div>
    )
}

export const CreatePollForBodyParser = async (poll: PollParseType) => {
    try {
        //@ts-ignore
        const { id } = await refEnv().api.LazyPoll.create(poll.poll)
        return { id };
    } catch (error) {
        ShowResteasyError(error)
    }
}

export const ShowResteasyError = (error: Error) => {
    console.log(error)
    if (error.name === "AxiosError") {
        const axiosError = error as AxiosError;
        const error_message = axiosError.response.data.error;
        if (error_message) window.log(error_message, "red")
    }
    throw error;
}